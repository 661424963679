import React, { useContext, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { SimpleCardTemplateComponent } from '../../../shared/component/general/simple-card-template.component'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { ExternalSources } from '../../../shared/enum'

export const GettingStartedSidebarComponent = () => {
  const [ trackEventInSegment, trackPageInSegment ] = getSegmentTrack()
  const { t } = useTranslation([ 'workspace' ])
  const { openTalkToExpert = () => {}, isTrialPineappleUser = () => false } = useContext<IDashboardContext>(DashboardContext)

  useEffect(() => {
    trackPageInSegment(`Page loaded: ${TrackEventType.VIEWED_GETTING_STARTED}`, TrackEventType.VIEWED_GETTING_STARTED)
  }, [])

  const bookFreeCall = () => {
    const isTrial = isTrialPineappleUser()
    trackEventInSegment(isTrial
      ? TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_TRIAL
      : TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_PAID)
    openTalkToExpert(isTrial
      ? ExternalSources.HubSpotCalendarForCarbonOnboardingUrl
      : ExternalSources.HubSpotCalendarForPaidUrl)
  }

  return (
    <SimpleCardTemplateComponent
      header={<div className="flex justify-content-center text-base font-medium">{t('labels.gettingStarted', { context: 'TalkToOurTeam' })}</div>}
      boxShadow="0px 6px 30px 6px rgba(0, 0, 0, 0.05)"
      className="bg-white border-round-lg text-base font-medium text-gray-700 p-4"
      headerClassName="border-gray-200"
    >
      <div className="flex flex-column">
        <div className="flex justify-content-center py-3">
          <img src="/assets/images/talk-to-our-team.png" className="w-13rem" alt="Out team" />
        </div>
        <div className="flex justify-content-center text-center font-light text-sm py-3 border-top-1 border-gray-100">
          <Trans t={t} ns="workspace" i18nKey="labels.gettingStarted_TalkOutTeamContent" />
        </div>
        <div className="flex justify-content-center h-2rem">
          <Button className="p-button-primary text-sm h-2rem" onClick={bookFreeCall}>
            {t('actions.book', { context: 'freeCall' })}
          </Button>
        </div>
      </div>
    </SimpleCardTemplateComponent>
  )
}
