import React, { useState } from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'

import { Facet, FilterDatasetType } from '../../../../__generated__/graphql'
import { FilterCheckboxList } from './filter-checkbox-list'
import { ROOT_CATEGORY } from '../../constant/categories'

interface ICommonCategoryGroupProps {
  category: Facet
  title: string
  onCategoriesChange: (categoryName: FilterDatasetType, updateValues: string[]) => void
  selectedValues: string[]
}

export const CommonCategoryGroup = ({
  category,
  title,
  onCategoriesChange,
  selectedValues
}: ICommonCategoryGroupProps) => {
  const [ showSection, setShowSection ] = useState(true)
  const onToggleSection = () => setShowSection(!showSection)

  const onItemChange = (values: string[]) => {
    if (ROOT_CATEGORY === category.name && values.length > 0) {
      setShowSection(false)
    }
    onCategoriesChange(category.name as FilterDatasetType, values)
  }

  const someItemsSelected = selectedValues.length > 0
  const itemsToShow = someItemsSelected ? category.values : category.values.filter(value => value.count > 0)
  const allItemsHaveZeroCount = category.values.every(value => value.count === 0)

  if (allItemsHaveZeroCount && !someItemsSelected) return null

  return (
    <div key={category.name} className={`mb-2 ${ROOT_CATEGORY === category.name ? 'pl-1' : 'pl-4'}`}>
      <div className={classnames(
        'flex w-full align-items-center py-1 border-bottom-1 border-gray-100 gap-2 text-gray-500 mb-1',
        { 'text-primary-500': someItemsSelected && !showSection }
      )}
      >
        <div
          data-testid="parent-category-name"
          className="flex flex-grow-1 align-items-center text-base line-height-2 cursor-pointer"
          onClick={onToggleSection}
        >
          {title}
        </div>
        <div data-testid="toggle-show-categories" className="flex w-1rem align-items-center cursor-pointer" onClick={onToggleSection}>
          <FontAwesomeIcon icon={showSection ? faChevronDown : faChevronUp} className="text-md" />
        </div>
      </div>
      {showSection && (
        <div className="mt-2">
          <FilterCheckboxList
            itemsList={itemsToShow}
            selectedItems={selectedValues}
            onItemChange={onItemChange}
          />
        </div>
      )}
    </div>
  )
}
