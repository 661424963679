import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useSearchParams } from 'react-router-dom'

import { ProductOverviewLayout } from '../layout/product-overview.layout'

import { store } from '../../../configureStore'
import { useProductOverviewQuery } from '../../shared/hook/query/use-product-overview.query'

import ProductContext from '../provider/context/product.context'
import { TProductContext } from '../interface/product-context.type'
import { CreateProductContainer } from '../../shared/container/create-product.container'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'
import { ImportProductContainer } from '../../shared/container/import-product.container'
import { TAGS } from '../../graphql/query'
import { isValid } from '../../shared/util/tools'
import { ProductLabel, ViewType } from '../../shared/enum'
import { CreateFolderContainer } from './dialog/create-folder.container'
import { MoveToFolderContainer } from './dialog/move-to-folder.container'
import { CreateScenarioContainer } from './dialog/create-scenario.container'
import { DeleteEntityContainer } from './dialog/delete-entity.container'

import DetailsPanel from '../../../component/model/details-panel/detailsPanel.container'
import ProductDetails from '../../../component/model/details-panel/product-details/productDetails.container'

import ProductMutationSelector from '../../../redux/selectors/productMutation.selector'
import ImpactSelectorDialog from '../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'
import { updateInventoryItemsSaga } from '../../../redux/sagas/inventoryTree.saga'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { MenuAction } from '../enum/menu-action'
import { ILifecycle, IProduct } from '../../model'
import { clearUiWhenDetailPanelCloseAction } from '../../../redux/actions/clear.actions'
import { EntityType } from '../../shared/enum/entity-type'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { SortingOrder, ProductSortField } from '../../../__generated__/graphql'
import { useProductNavigate } from '../hook/use-product-navigate'
import { useProductAnalysisNavigate } from '../../analysis/hook/use-product-analysis-navigate'
import { selectedWorkspaceVar } from '../../../graphql/cache'

export const ProductOverviewContainer = () => {
  const { t } = useTranslation('common')
  const { productAnalysisNavigate } = useProductAnalysisNavigate()
  const [ searchParams ] = useSearchParams()
  const { space: { id: spaceID = '' } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { productNavigate } = useProductNavigate()
  const folderID = searchParams.get('folderId') || ''

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const productMutator = ProductMutationSelector.productMutator(state)
  const selectedProductId = SelectedProductSelector.productId(state)

  const {
    label = ProductLabel.Product,
    query,
    categories,
    tags,
    currentPage = 1,
    cardPageSize,
    tablePageSize,
    selectedViewType,
    selectedEntity,
    selectedAction,
    selectedSortBy = ProductSortField.Name,
    selectedSortOrder = SortingOrder.Asc,
    updateProduct
  } = useContext<TProductContext>(ProductContext)
  const { showCreateComparisonDialog = false } = useContext<IDashboardContext>(DashboardContext)

  const isCardView = selectedViewType === ViewType.Grid
  const queryParam = {
    spaceID,
    labels: [ label ],
    query,
    tags,
    categories,
    folderID,
    currentPage,
    sortBy: { field: selectedSortBy, order: selectedSortOrder },
    pageSize: isCardView ? cardPageSize : tablePageSize
  }
  const {
    loading: loadingProductOverview,
    productsData,
    favoriteProductsData,
    refetchProducts = () => {},
    refetchFavProducts = () => {}
  } = useProductOverviewQuery(queryParam)

  const {
    loading: loadingTags,
    data: tagsData,
    refetch: refetchTags
  } = useQuery(TAGS, {
    skip: !spaceID,
    variables: { query: '', spaceID },
    fetchPolicy: 'no-cache'
  })

  const [ trackEventInSegment ] = getSegmentTrack()
  const { products: { items: allProducts = [], currentFolder = null, total = 0 } = {} } = productsData || {}
  const { favoriteProducts: { items: favProducts = [] } = {} } = favoriteProductsData || {}

  useEffect(() => {
    if (!loadingProductOverview) {
      const emptyFilterResult = allProducts.length === 0 && isFilterActive()
      updateProduct({
        favProducts,
        totalItems: total,
        allProducts,
        loadingProductOverview,
        currentFolder,
        emptyFilterResult,
        refetchProducts,
        refetchFavProducts
      })
    } else {
      updateProduct({ loadingProductOverview })
    }
  }, [ loadingProductOverview, currentFolder, favoriteProductsData, productsData ])

  useEffect(() => {
    if (!loadingTags && tagsData) {
      const { tags: tagOptions = [] } = tagsData
      updateProduct({ tagOptions })
    }
  }, [ loadingTags, tagsData ])

  useEffect(() => {
    const clearSelected = selectedAction === MenuAction.OpenProductDetail
      && !allProducts.some((product: IProduct) => (product?.id === selectedEntity?.id))
    if (clearSelected) {
      updateProduct({ selectedEntity: null })
      store.dispatch(clearUiWhenDetailPanelCloseAction())
    }
  }, [ allProducts ])

  const backLabel = label === ProductLabel.Product
    ? t('labels.product', { count: 2 }) : t('labels.object')

  const isFilterActive = () => (isValid(query) && query !== '')
      || (isValid(tags) && tags?.length !== 0)
      || (isValid(categories) && categories?.length !== 0)

  const reloadProductList = (trackEventType?: TrackEventType | null) => {
    if (trackEventType) {
      trackEventInSegment(trackEventType)
    }

    refetchProducts()
    refetchFavProducts()
  }

  const afterCreateProduct = (selectedProductId: string) => {
    trackEventInSegment(TrackEventType.CREATED_PRODUCT)
    productNavigate({ selectedProductId, backLabel })
  }

  const afterCreateFolder = () => {
    reloadProductList(TrackEventType.CREATED_FOLDER)
  }

  const afterImportProduct = () => {
    reloadProductList(TrackEventType.IMPORTED_PRODUCT)
  }

  const afterMoveToFolder = () => {
    reloadProductList(TrackEventType.MOVED_TO_FOLDER)
  }

  const afterDeleteProduct = () => {
    trackEventInSegment(TrackEventType.DELETED_PRODUCT)
    if (allProducts.length <= 1) {
      updateProduct({ currentPage: 1 })
    }
    reloadProductList()
  }

  const afterImpactChange = () => {
    reloadProductList(TrackEventType.IMPORTED_PRODUCT)
    store.dispatch(updateInventoryItemsSaga())
  }

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    trackEventInSegment(TrackEventType.OPENED_COMPARISON)

    if (comparisonEntity.__typename !== EntityType.LifeCycle) {
      productAnalysisNavigate({
        selectedProductId: selectedEntity?.id,
        selectedSecondaryEntityId: comparisonEntity.id,
        selectedSecondaryEntityType: comparisonEntity.__typename,
        backLabel
      })
    }
  }

  return (
    <>
      <ProductOverviewLayout />

      <CreateProductContainer
        label={label}
        folderID={folderID}
        afterCreateProduct={afterCreateProduct}
      />

      <CreateFolderContainer afterCreateFolder={afterCreateFolder} />

      <ImportProductContainer afterImportProduct={afterImportProduct} />

      <MoveToFolderContainer afterMoveToFolder={afterMoveToFolder} />

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} /> }

      <CreateScenarioContainer />

      <DeleteEntityContainer afterDeleteProduct={afterDeleteProduct} />

      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <ProductDetails updatePageItems={() => {
          refetchTags()
          reloadProductList(null)
        }}
        />
      </DetailsPanel>

      {/* // TODO : From v0.9 : To be refactored */}
      { selectedProductId && <ImpactSelectorDialog productMutator={productMutator} updatePageItems={afterImpactChange} /> }
    </>
  )
}
