import { useContext, useEffect } from 'react'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { Navigation } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'

const trackLifecycleNavigation = (activeTabKey?: InventoryViewType) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const activeIndex = Object.values(InventoryViewType).findIndex(viewType => viewType === activeTabKey) || 0
  const TabEventTypes: TrackEventType[] = [
    TrackEventType.VIEWED_LIFECYCLE_MODEL,
    TrackEventType.VIEWED_LIFECYCLE_TABLE,
    TrackEventType.VIEWED_LIFECYCLE_BAR_CHART,
    TrackEventType.VIEWED_LIFECYCLE_DONUT_CHART
  ]
  trackEventInSegment(TabEventTypes[activeIndex])
}

export const useLifecycleNavigate = () => {
  const { lifecycleId: lifecycleIdFromURL } = useParams()
  const { space: { id: spaceId = null, slug: workspaceSlug = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account: { id: accountId = null } = {} } = useReactiveVar(selectedAccountVar) || {}

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { addToBackToList = () => {}, removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const activeTab = searchParams.get('activeTab')

  useEffect(() => {
    const selectedInventoryViewType = activeTab ? activeTab as InventoryViewType : InventoryViewType.Model
    trackLifecycleNavigation(selectedInventoryViewType)
    updateLifecycleDetail({ selectedInventoryViewType })
  }, [ activeTab ])

  const navigateBackToSource = () => {
    navigate(-1)
    removeFromBackToList()
  }

  const lifecycleNavigate = ({
    selectedLifecycleId,
    activeTab = InventoryViewType.Model,
    replace = true,
    backLabel,
  }: {
    selectedLifecycleId?: string;
    activeTab?: InventoryViewType;
    replace?: boolean;
    backLabel?: string;
  } = {}) => {
    const lifecycleId = selectedLifecycleId || lifecycleIdFromURL
    if (!accountId || !workspaceSlug || !lifecycleId) return;

    const isOnLifecycleDetail = isValid(matchPath(Navigation.LifeCycleDetail, pathname));
    const isSameLifecycle = !selectedLifecycleId || selectedLifecycleId === lifecycleIdFromURL;

    if (isSameLifecycle && isOnLifecycleDetail) {
      setSearchParams({ activeTab }, { replace });
      return;
    }

    if (backLabel) addToBackToList(backLabel);

    navigate({
      pathname: generatePath(Navigation.LifeCycleDetail, {
        accountId,
        workspaceSlug,
        lifecycleId,
      }),
      search: `?activeTab=${activeTab}`,
    });
  };

  return {
    spaceId,
    accountId,
    workspaceSlug,
    activeTab,
    lifecycleNavigate,
    navigateBackToSource,
  }
}
