import { gql } from '@apollo/client'

export default gql`
  fragment referenceProduct on ReferenceProduct {
    id
    name
    type
    amount
    databaseID
    databaseName
    description
    technology
    timeDescription
    timeFrom
    timeUntil
    geography
    databaseCategoryPath
    location {
      id
      name
    }
    referenceUnit {
      id
      name
    }
    referenceProperty {
      id
      name
    }
    category {
      id
      name
    }
    parentCategory {
      id
      name
    }
  }
`
