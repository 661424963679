import React, { useContext, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { TabMenu } from 'primereact/tabmenu'

import { useTranslation } from 'react-i18next'
import { selectedWorkspaceVar } from '../../../../graphql/cache'

import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { TLifecycleDetailContext } from '../../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../provider/context/lifecycle-detail.context'
import { ChangeImpactCategoryContainer } from '../../../product/container/change-impact-category.container'
import { useLifecycleNavigate } from '../../hook/use-lifecycle-navigate'

export const TaskbarDetailComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { lifecycleNavigate } = useLifecycleNavigate()
  const { selectedInventoryViewType, refetchLifecycle } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { impactCategory } = space || {}

  useEffect(() => {
    if (impactCategory?.id) {
      refetchLifecycle && refetchLifecycle()
    }
  }, [ impactCategory?.id ])

  const items = [
    { label: t('labels.tab', { context: 'model' }), className: 'cy-tab-model' },
    { label: t('labels.tab', { context: 'table' }), className: 'cy-tab-table' },
    { label: t('labels.tab', { context: 'barChart' }), className: 'cy-tab-barchart' },
    { label: t('labels.tab', { context: 'donutChart' }), className: 'cy-tab-doughnut' }
  ]

  const getActiveIndex = (selectedViewType?: InventoryViewType) => Object.values(InventoryViewType).findIndex(viewType => viewType === selectedViewType) || 0

  const handleTabChange = (event: any) => {
    const { index = 0 } = event || {}
    const activeTab = Object.values(InventoryViewType)[index]
    lifecycleNavigate({ activeTab })
  }

  return (
    <div className="flex align-items-center justify-content-start w-full">
      <div className="flex flex-grow-1">
        <TabMenu model={items} activeIndex={getActiveIndex(selectedInventoryViewType)} onTabChange={(event: any) => handleTabChange(event)} />
      </div>
      <div className="flex flex-none h-full mb-1">
        <ChangeImpactCategoryContainer />
      </div>
    </div>
  )
}
