import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Chip } from 'primereact/chip'
import { Button } from 'primereact/button'

import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { Category } from '../../../../__generated__/graphql'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../../shared/util/impact'
import { castToJoinedAlphaNumericString } from '../../../shared/util/tools'

const FilterChipsWrapper = styled.div`
  .p-chip {
    max-width: 15rem;
  }

  .p-chip-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .p-chip-remove-icon {
    padding-top: 0.15rem;
    font-size: 0.75rem;
  }

  .p-chip-remove-icon:focus {
    box-shadow: none;
  }
`
type TFilterChipsComponentProps = {
  onClearFilters?: Function
}

export const FilterChipsComponent = ({
  onClearFilters
}:TFilterChipsComponentProps) => {
  const { t } = useTranslation([ 'common', 'impact-dataset' ])
  const {
    updateFilterValue,
    categoryFilterItems = [],
    selectedDatabases = [],
    selectedProperties = [],
    selectedLocations = [],
    selectedLocationRegions = [],
    selectedCommonCategories = [],
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)

  const [ isShowingFilters, setIsShowingFilters ] = useState<boolean>(false)

  const removeCategoryFilterItem = (categoryToRemove: Category) => {
    const categoryToRemoveIndex = categoryFilterItems.findIndex(categoryFilterItem => categoryFilterItem.selectedCategory?.id === categoryToRemove.id)

    categoryFilterItems[categoryToRemoveIndex] = {
      ...categoryFilterItems[categoryToRemoveIndex],
      selectedCategory: null
    }
    const updatedFilterItems = categoryFilterItems
      .filter((value, index) => value && index <= categoryToRemoveIndex)
    updateFilterValue({ categoryFilterItems: updatedFilterItems })
  }

  const removeCommonCategoryFilterItem = (groupName: string, categoryName: string) => {
    const updatedSelectedCommonCategories = selectedCommonCategories.map(category => {
      if (category.name === groupName) {
        return {
          ...category,
          values: category.values.filter(value => value !== categoryName)
        }
      }
      return category
    })

    updateFilterValue({ selectedCommonCategories: updatedSelectedCommonCategories })
  }

  const removeDatabaseFilterItem = (databaseName: string) => {
    const selectedDatabaseNames = [ ...selectedDatabases ]
    selectedDatabaseNames.splice(selectedDatabaseNames.findIndex(elt => elt === databaseName), 1)
    updateFilterValue({ selectedDatabases: selectedDatabaseNames, hasActiveDatabaseFilter: true })
    setSelectedDatabasesStorageItem(selectedDatabaseNames)
  }

  const removePropertyFilterItem = (propertyName: string) => {
    const selectedPropertyNames = [ ...selectedProperties ]
    selectedPropertyNames.splice(selectedPropertyNames.findIndex(elt => elt === propertyName), 1)
    updateFilterValue({ selectedDatabases: selectedPropertyNames })
  }

  const removeLocationItem = (locationName: string) => {
    updateFilterValue({
      selectedLocations: selectedLocations.filter(location => location !== locationName),
    })
  }

  const removeLocationRegionItem = (locationRegionName: string) => {
    updateFilterValue({
      selectedLocationRegions: selectedLocationRegions.filter(locationRegion => locationRegion !== locationRegionName),
    })
  }

  const toggleFilters = () => setIsShowingFilters(!isShowingFilters)

  const activeFiltersCounter = () => {
    const categoriesCounter = categoryFilterItems.length - 1;
    const commonCategoriesCounter = selectedCommonCategories.reduce((acc, category) => acc + category.values.length, 0);
    return categoriesCounter + selectedDatabases.length + selectedLocations.length + selectedProperties.length + commonCategoriesCounter + selectedLocationRegions.length;
  }

  return (
    <div className="py-3">
      <div className="flex w-full align-items-center py-1 border-bottom-1 border-gray-100 gap-2 text-gray-500">
        <div
          data-testid="parent-category-name"
          className="flex flex-grow-1 align-items-center text-base font-medium text-gray-500 line-height-2 cursor-pointer text-gray-500"
          onClick={toggleFilters}
        >
          { t('labels.activeFilters', { ns: 'impact-dataset', count: activeFiltersCounter() }) }
        </div>
        <div data-testid="toggle-show-categories" className="flex w-1rem align-items-center text-gray-500 cursor-pointer" onClick={toggleFilters}>
          <FontAwesomeIcon icon={isShowingFilters ? faChevronDown : faChevronUp} className="text-md" />
        </div>
      </div>
      { isShowingFilters && (
        <FilterChipsWrapper className="flex w-full flex-wrap gap-1 mt-2">
          { categoryFilterItems.map(categoryFilterItem => {
            const { selectedCategory } = categoryFilterItem
            return (
              <div key={`chip-filter-category-${selectedCategory?.id}`} className="flex mb-1">
                { selectedCategory
              && (
                <Chip
                  removable
                  label={selectedCategory.name}
                  onRemove={() => removeCategoryFilterItem(selectedCategory)}
                  className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
                />
              ) }
              </div>
            )
          })}

          { selectedCommonCategories.map(categoryGroup => categoryGroup.values.map(category => (
            <div key={`chip-filter-custom-category-${categoryGroup.name}-${castToJoinedAlphaNumericString(category)}`} className="flex mb-1">
              <Chip
                removable
                label={category}
                onRemove={() => removeCommonCategoryFilterItem(categoryGroup.name, category)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            </div>
          )))}

          { selectedDatabases.map(databaseName => (
            <div key={`chip-filter-database-${databaseName}`} className="flex mb-1">
              { databaseName
            && (
              <Chip
                removable
                label={databaseName}
                onRemove={() => removeDatabaseFilterItem(databaseName)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            ) }
            </div>
          ))}

          { selectedProperties.map(propertyName => (
            <div key={`chip-filter-database-${castToJoinedAlphaNumericString(propertyName)}`} className="flex mb-1">
              { propertyName
            && (
              <Chip
                removable
                label={propertyName}
                onRemove={() => removePropertyFilterItem(propertyName)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            ) }
            </div>
          ))}

          { selectedLocations.map(locationName => (
            <div key={`chip-filter-database-${locationName}`} className="flex mb-1">
              <Chip
                removable
                label={locationName}
                onRemove={() => removeLocationItem(locationName)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            </div>
          ))}
          { selectedLocationRegions.map(locationRegionName => (
            <div key={`chip-filter-database-${locationRegionName}`} className="flex mb-1">
              <Chip
                removable
                label={locationRegionName}
                onRemove={() => removeLocationRegionItem(locationRegionName)}
                className="text-xs text-primary-500 bg-primary-50 white-space-nowrap overflow-hidden text-overflow-ellipsis border-round-sm"
              />
            </div>
          ))}

          {onClearFilters && activeFiltersCounter() > 0 && (
            <Button className="p-button-outlined p-button-plain text-sm h-2rem px-1" onClick={() => onClearFilters()}>
              <span className="ml-1">{ t('labels.clearAll') }</span>
            </Button>
          )}
        </FilterChipsWrapper>
      )}
    </div>
  )
}
