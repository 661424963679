import { Facet, FacetValue, FilterDatasetType } from '../../../__generated__/graphql'
import { TImpactDatasetProvider } from '../interface/impact-dataset.context'

type CountsDictionary = {
  [key: string]: number
}

export const getFacetsCountsDictionary = (facet: Facet): CountsDictionary => facet.values.reduce((acc, value) => ({
  ...acc,
  [value.name]: value.count
}), {})

const getSortedCommonCategories = (commonCategoryList: Facet[] = []) => {
  const withSortedValues = [ ...commonCategoryList ].map(category => {
    const categoryValues = [ ...category.values ]
    return ({
      ...category,
      values: categoryValues.sort((a, b) => {
        if (a.name.toLowerCase() === 'other') return 1
        if (b.name.toLowerCase() === 'other') return -1

        return a.name.localeCompare(b.name)
      })
    })
  })

  return [ ...withSortedValues ].sort((a, b) => {
    const orderNumA = Number(a.name.slice(1))
    const orderNumB = Number(b.name.slice(1))
    return orderNumA - orderNumB
  })
}

export const getFacetsLists = (facets: Facet[] = []) => {
  const facetsLists: Partial<TImpactDatasetProvider> = {
    commonCategoryList: []
  }

  facets.forEach(facet => {
    switch (facet.name) {
    case FilterDatasetType.Database:
      facetsLists.databaseList = facet.values
      break
    case FilterDatasetType.FlowProperty:
      facetsLists.propertyList = facet.values
      break
    case FilterDatasetType.Location: {
      const sorted = [ ...facet.values ].sort((a, b) => a.name.localeCompare(b.name))
      facetsLists.locationList = sorted
      break
    }
    case FilterDatasetType.LocationRegion: {
      const sorted = [ ...facet.values ].sort((a, b) => a.name.localeCompare(b.name))
      facetsLists.locationRegionList = sorted
      break
    }
    default: {
      const isNotEmpty = facet.values.length > 0

      if (isNotEmpty) {
        facetsLists.commonCategoryList?.push(facet)
      }
    }
    }
  })

  facetsLists.commonCategoryList = getSortedCommonCategories(facetsLists.commonCategoryList)

  return facetsLists
}

const updateCountsInList = (list: FacetValue[], newCounts: CountsDictionary) => list.map(item => ({
  ...item,
  count: newCounts[item.name] || 0
}))

export const updateFacetsListsCounts = (
  facets: Facet[] = [],
  databaseList: FacetValue[] = [],
  locationList: FacetValue[] = [],
  propertyList: FacetValue[] = [],
  commonCategoryList: Facet[] = []
) => {
  const facetsLists: Partial<TImpactDatasetProvider> = {
    commonCategoryList: []
  }

  facets.forEach(facet => {
    const newCounts = getFacetsCountsDictionary(facet)

    switch (facet.name) {
    case FilterDatasetType.Database:
      facetsLists.databaseList = updateCountsInList(databaseList, newCounts)
      break
    case FilterDatasetType.Location:
      facetsLists.locationList = updateCountsInList(locationList, newCounts)
      break
    case FilterDatasetType.FlowProperty:
      facetsLists.propertyList = updateCountsInList(propertyList, newCounts)
      break
    default: {
      const categoryGroupValues = commonCategoryList.find(categoryGroup => categoryGroup.name === facet.name)?.values || []
      const countList = updateCountsInList(categoryGroupValues, newCounts)

      facetsLists.commonCategoryList?.push({
        ...facet,
        values: countList
      })
    }
    }
  })
  return facetsLists
}

