import React, { useState } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'primereact/tooltip'
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { faPenSquare } from '@fortawesome/pro-light-svg-icons'

type NotCompatibleImpactIconProps = {
  hasChild?: boolean,
  addRightSpace?: boolean,
  tooltipPosition?: 'left' | 'top' | 'right' | 'bottom',
  isElementaryFlow?: boolean
  onClick?: Function
  isReadOnly?: boolean
}

export const NotCompatibleImpactIcon: React.FC<NotCompatibleImpactIconProps> = ({
  hasChild,
  addRightSpace,
  tooltipPosition = 'left',
  isElementaryFlow = false,
  onClick = () => {},
  isReadOnly = false
}) => {
  const { t } = useTranslation([ 'product' ])
  const [ showEditButton, setShowEditButton ] = useState<boolean>(false)
  const tooltipLabelKey = hasChild ? 'hasItemsWithImpactNotCompatible' : 'treeItemWithImpactNotCompatible'
  const labelSuffix = isElementaryFlow ? '_elementaryFlow' : ''
  const randomId = Math.random().toString(36).substring(7)
  const id = `impact-icon-${randomId}`

  return (
    <>
      <Tooltip target={`.${id}`} position={tooltipPosition} showDelay={500}>
        <Trans t={t} ns="product" i18nKey={`labels.${tooltipLabelKey + labelSuffix}`} />
      </Tooltip>
      <div
        className={classNames(`${id} flex justify-content-center align-items-center`, {
          'mr-2': addRightSpace,
        })}
      >
        <span onMouseEnter={() => setShowEditButton(true)} onMouseLeave={() => setShowEditButton(false)}>
          {showEditButton && !hasChild && !isReadOnly ? (
            <FontAwesomeIcon
              data-testid="tree-item-edit-impact-icon"
              icon={faPenSquare}
              className="text-2xl text-blue-500 mt-1"
              onClick={(event: React.MouseEvent) => onClick(event)}
            />
          )
            : (
              <FontAwesomeIcon
                data-testid="tree-item-invalid-impact-icon"
                icon={faExclamationTriangle}
                className="text-xl text-orange-700 mt-1"
              />
            )}
        </span>
      </div>
    </>
  )
}
