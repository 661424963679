import React, { useContext, useEffect } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { useTranslation } from 'react-i18next'

import { TProductDetailContext } from '../../../product/interface/product-detail-context.type'
import ProductDetailContext from '../../../product/provider/context/product-detail.context'
import { InventoryViewType } from '../../../product/enum/inventory-view-type'
import { ChangeImpactCategoryContainer } from '../../../product/container/change-impact-category.container'
import { useProductAnalysisNavigate } from '../../hook/use-product-analysis-navigate'
import { AnalysisMode } from '../../enum/analysis'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'
import { store } from '../../../../configureStore'
import { setIsDetailPanelReadonlyV1Action } from '../../../../redux/actions/flags.actions'

export const TaskbarComponent = () => {
  const { t } = useTranslation([ 'common' ])
  const { productAnalysisNavigate, activeTab } = useProductAnalysisNavigate()
  const { analysisMode } = useContext<TAnalysisContext>(AnalysisContext)
  const { selectedInventoryViewType } = useContext<TProductDetailContext>(ProductDetailContext)
  const comparisonMode = analysisMode === AnalysisMode.Comparison

  useEffect(() => {
    if (!comparisonMode && activeTab && activeTab !== InventoryViewType.Model) {
      store.dispatch(setIsDetailPanelReadonlyV1Action(true))
    }
  }, [ activeTab ])

  const items = [
    { label: t('labels.tab', { context: 'model' }), className: 'cy-tab-model', activeTab: InventoryViewType.Model },
    { label: t('labels.tab', { context: 'table' }), className: 'cy-tab-table', activeTab: InventoryViewType.FlatView },
    { label: t('labels.tab', { context: 'stackedChart' }), className: 'cy-tab-stacked', activeTab: InventoryViewType.StackedChart }
  ]

  const getActiveIndex = (selectedViewType?: InventoryViewType) => items.findIndex(viewType => viewType.activeTab === selectedViewType) || 0

  const handleTabChange = (event: any) => {
    const { value: { activeTab } } = event || {}
    productAnalysisNavigate({ activeTab })
  }

  return (
    <div className="flex align-items-center justify-content-start w-full">
      <div className="flex flex-grow-1">
        <TabMenu model={items} activeIndex={getActiveIndex(selectedInventoryViewType)} onTabChange={(event: any) => handleTabChange(event)} />
      </div>
      <div className="flex flex-none h-full mb-1">
        <ChangeImpactCategoryContainer />
      </div>
    </div>
  )
}
