import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { EElementaryFlowColumn } from '../enum/impact-dataset-column'

import { IElementaryFlowResult } from '../interface/impact-dataset-result'
import { transformToElementaryFlowResult } from '../util/transform'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { TableBodyTemplateComponent } from './table/table-body-template.component'
import { IProduct, IReferenceProduct } from '../../model'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TableHeaderComponent } from './table/table-header-template.component'
import { ReferenceProductType } from '../enum/reference-product'
import { DatasetSortField, SortingOrder } from '../../../__generated__/graphql'

const TableWrapper = styled.div`
  .p-datatable {
    min-width: 52rem;

    .p-datatable-tbody {
      tr {
        cursor: pointer;
        border-radius: 0.5rem;
        box-shadow: 0px 5px 25px 5px rgba(0, 0, 0, 0.05);

        &:hover {
          box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.25);
          background-color: var(--primary-50);
        }

        td {
          cursor: auto;
        }
      }
    }
  }

  .p-datatable-emptymessage {
    display:none !important;
  }
`

type TElementaryFlowComponentProp = {
  showResults: boolean
}
export const ElementaryFlowComponent = ({ showResults }: TElementaryFlowComponentProp) => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ])
  const {
    selectedSortBy,
    selectedSortOrder,
    referenceProducts = [],
    enableElementaryFlowComparison = false,
    comparisonElementaryFlowItems = [],
    updateFilterValue,
    updateImpactDataset
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)
  const { selectedEntity = {}, notifyToastPrime } = useContext<IDashboardContext>(DashboardContext)

  const { referenceProduct: currentReferenceProduct } = selectedEntity as IProduct
  const impactDatasetResults = transformToElementaryFlowResult([
    (currentReferenceProduct?.type === ReferenceProductType.ElementaryFlow ? currentReferenceProduct : {}),
    ...referenceProducts.filter(referenceProduct => referenceProduct.id !== currentReferenceProduct?.id)
  ])

  const getSelectedRowIndex = (rowData: IReferenceProduct) => comparisonElementaryFlowItems.findIndex((item: IReferenceProduct) => item.id === rowData.id)

  const getReferenceProduct = (id: string) => referenceProducts.find((item: IReferenceProduct) => item.id === id)

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const onRowSelection = (e: any) => {
    const rowData = e.value as IElementaryFlowResult
    const selectedIndex = getSelectedRowIndex(rowData)

    if (selectedIndex < 0 && comparisonElementaryFlowItems.length >= 3) {
      notifyToastPrime?.show({
        severity: 'warn',
        detail: t('messages.maxComparisonLimitDetail', { context: 'warn' }),
        summary: t('messages.maxComparisonLimitSummary', { context: 'warn' }),
        life: 3000
      })
      return
    }

    const updatedComparisonItems = [ ...comparisonElementaryFlowItems ]
    if (selectedIndex < 0) {
      const selectedReferenceProduct = getReferenceProduct(rowData.id)
      selectedReferenceProduct && updatedComparisonItems.push(selectedReferenceProduct)
    } else {
      updatedComparisonItems.splice(selectedIndex, 1)
    }
    updateImpactDataset({ comparisonElementaryFlowItems: updatedComparisonItems })
  }

  const bodyTemplate = (rowData: IElementaryFlowResult, column: EElementaryFlowColumn) => (
    <TableBodyTemplateComponent
      value={rowData}
      column={column}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      onRowSelection={onRowSelection}
      isMouseOver={rowData.id === mouseOverOn}
      isSelected={getSelectedRowIndex(rowData) >= 0}
    />
  )

  const handleSortTable = (sortBy: DatasetSortField, sortOrderTo: SortingOrder) => {
    if (selectedSortBy === sortBy && selectedSortOrder === SortingOrder.Desc) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
      return
    }

    updateFilterValue({ selectedSortBy: sortBy, selectedSortOrder: sortOrderTo })
  }

  const headerTemplate = ({
    label,
    column,
    sortBy = DatasetSortField.Name,
    sortDisabled = true
  } : {
    label: string,
    column?: EElementaryFlowColumn,
    sortBy?: DatasetSortField,
    sortDisabled?: boolean
  }) => (
    <TableHeaderComponent
      {...{
        selectedSortOrder, sortDisabled, label, column, sortBy, selectedSortBy, onSortClick: handleSortTable
      }}
    />
  )

  const setRowColorForSelected = (impactDataset: IElementaryFlowResult) => {
    const isCurrentActiveReference = currentReferenceProduct?.id === impactDataset.id
    const isSelectedReference = getSelectedRowIndex(impactDataset) >= 0
    return { 'bg-primary-50': isCurrentActiveReference || isSelectedReference }
  }

  return (
    <TableWrapper className="flex w-full">
      <TableWrapperComponent>
        <DataTable
          value={showResults ? impactDatasetResults : []}
          rowClassName={setRowColorForSelected}
        >
          {
            enableElementaryFlowComparison && (
              <Column
                field={EElementaryFlowColumn.Selection}
                className="border-round-left-lg"
                style={{ width: '3rem' }}
                body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Selection)}
                header={() => <></>}
              />
            )
          }
          <Column
            field={EElementaryFlowColumn.DatasetName}
            className="border-round-left-lg"
            style={{ minWidth: '12rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.DatasetName)}
            header={headerTemplate({ label: t('labels.elementaryFlowName'), column: EElementaryFlowColumn.DatasetName, sortDisabled: false })}
          />

          <Column
            field={EElementaryFlowColumn.Compartment}
            style={{ width: '12rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Compartment)}
            header={headerTemplate({ label: t('labels.compartment'), column: EElementaryFlowColumn.Compartment })}
          />

          <Column
            field={EElementaryFlowColumn.SubCompartment}
            style={{ width: '12rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.SubCompartment)}
            header={headerTemplate({ label: t('labels.compartment', { context: 'sub' }), column: EElementaryFlowColumn.SubCompartment })}
          />

          <Column
            field={EElementaryFlowColumn.Property}
            style={{ width: '9rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Property)}
            header={headerTemplate({ label: t('labels.property', { ns: 'common' }), column: EElementaryFlowColumn.Property })}
          />

          <Column
            field={EElementaryFlowColumn.Action}
            className="border-round-right-lg"
            style={{ width: '10rem' }}
            body={(rowData: IElementaryFlowResult) => bodyTemplate(rowData, EElementaryFlowColumn.Action)}
            header={() => <></>}
          />
        </DataTable>
      </TableWrapperComponent>
    </TableWrapper>
  )
}
