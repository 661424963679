import React, { useContext, useState } from 'react'
import { AiOutlineTag } from 'react-icons/ai'
import { FiGrid } from 'react-icons/fi'

import { OverviewCardTemplateLayout } from '../../../shared/layout/overview-card-template.component'
import { OverviewViewComponent } from '../../../shared/component/overview/overview-view.component'
import { ListType, ProductLabel } from '../../../shared/enum'

import { MenuProductContainer } from '../../container/menu/menu-product.container'
import { TProductContext } from '../../interface/product-context.type'
import ProductContext from '../../provider/context/product.context'
import { MenuAction } from '../../enum/menu-action'
import { normalize } from '../../../../utils/tools'
import { useProductNavigate } from '../../hook/use-product-navigate'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'

type ProductTemplateItemComponentProps = {
  id: string,
  prefix: string,
  label?: ProductLabel,
  name: string,
  tags: string[],
  favorite: boolean,
  updatedAt: string,
  description: string
}
export const ViewTemplateProductComponent = ({
  id = '',
  label = ProductLabel.Product,
  name = '',
  tags = [],
  prefix = 'all',
  favorite = false,
  updatedAt = '',
  description = ''
}: ProductTemplateItemComponentProps) => {
  const { productNavigate } = useProductNavigate()
  const [ isMouseOver, setIsMouseOver ] = useState<boolean>(false)
  const { selectedEntity, selectedAction } = useContext<TProductContext>(ProductContext)

  const isProduct = label === ProductLabel.Product
  const icon = isProduct ? <AiOutlineTag size={20} /> : <FiGrid size={20} />
  const [ trackEventInSegment ] = getSegmentTrack()
  const listType = isProduct ? ListType.Product : ListType.Object

  const navigateToProductDetail = () => {
    trackEventInSegment(TrackEventType.VIEWED_PRODUCT)
    productNavigate({ selectedProductId: id })
  }

  const isSelected = (id === selectedEntity?.id && selectedAction === MenuAction.OpenProductDetail)

  return (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      data-cy={`item-named-${normalize(name)}`} // TODO: find a good replacement of normalize()
      className="flex w-full h-full p-0 m-0"
    >
      <OverviewCardTemplateLayout listType={listType} selected={isSelected} key={id} clickHandler={navigateToProductDetail}>
        <OverviewViewComponent
          id={`${prefix}-${id}`}
          icon={icon}
          textLabel={name}
          description={description}
          tags={tags}
          updatedAt={updatedAt}
        />

        <div className="absolute bottom-0 right-0 pb-3 pr-3">
          <MenuProductContainer {...{
            id, name, favorite, isMouseOver, isSelected
          }}
          />
        </div>
      </OverviewCardTemplateLayout>
    </div>
  )
}
