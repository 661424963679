import React, { useContext } from 'react'
import styled from 'styled-components'
import { Tag } from 'primereact/tag'
import moment from 'moment'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/pro-regular-svg-icons'

import { AiOutlineTag } from 'react-icons/ai'
import { FiGrid } from 'react-icons/fi'

import { MenuProductContainer } from '../../container/menu/menu-product.container'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { ProductLabel, ProductOverviewTableColumn, TProductOverviewTableColumn } from '../../../shared/enum'
import { EntityType } from '../../../../utils/const'
import { MenuFolderContainer } from '../../container/menu/menu-folder.container'

const ProductName = styled.div`
  width: calc(100% - 7rem);
  max-height: 4rem;
  overflow: hidden;
  line-height: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ProductIcon = styled.div`
  background: var(--primarydark-400);
`

const ProductMenu = styled.div`
  width: 5.5rem;
  height: 100%;
`

const TagsDescriptionWrapper = styled.div`
  width: 100%;
  max-height: 4rem;
  overflow: hidden;
  line-height: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export type TableBodyTemplateComponentProps = {
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: TProductOverviewTableColumn,
  value: any
}

export const TableBodyTemplateComponent = ({
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = ProductOverviewTableColumn.Name,
  value
}: TableBodyTemplateComponentProps) => {
  const { label, selectedEntity } = useContext<TProductContext>(ProductContext)

  const isFolder = value?.__typename === EntityType.FOLDER
  const isProduct = !isFolder && label === ProductLabel.Product
  const isObject = !isFolder && label === ProductLabel.Object
  const isSelected = selectedEntity?.id === value?.id

  const getBodyTemplate = () => ({
    [ProductOverviewTableColumn.Name]: (
      <div className={classNames('flex align-items-center p-2 py-0 w-full border-round-left-lg', {
        'border-green-500 border-1 border-right-none': isSelected
      })}
      >
        <ProductIcon
          data-testid="table-body-template-icon"
          className={classNames('flex align-items-center justify-content-center w-2rem h-2rem border-round-md', {
            'bg-primarydark-50 text-primarydark-400': isObject,
            'bg-primary-50 text-primary-500': isProduct,
            'bg-primarydark-400 text-white': isFolder
          })}
        >
          { !isFolder && isProduct && <AiOutlineTag data-testid="product-template-icon" size={20} /> }
          { !isFolder && isObject && <FiGrid data-testid="object-template-icon" size={20} /> }
          { isFolder && <FontAwesomeIcon data-testid="folder-template-icon" icon={faFolder} size="lg" /> }
        </ProductIcon>
        <ProductName className="surface-overlay align-items-center px-2 text-sm text-gray-700">
          { value?.name }
        </ProductName>
        <ProductMenu className="flex align-items-center justify-content-start">
          { isFolder
            ? (
              <MenuFolderContainer {...{
                id: value?.id,
                name: value?.name,
                isMouseOver: value?.isMouseOver
              }}
              />
            )
            : (
              <MenuProductContainer {...{
                id: value?.id,
                name: value?.name,
                favorite: value?.favorite,
                isMouseOver: value?.isMouseOver,
                isSelected
              }}
              />
            )}
        </ProductMenu>
      </div>
    ),
    [ProductOverviewTableColumn.LastChanged]: (
      <div className={classNames('flex px-3 align-items-center w-full text-sm text-gray-700', {
        'border-green-500 border-y-1': isSelected
      })}
      >
        <span className="line-height-1">{ value?.updatedAt && moment(new Date(value?.updatedAt)).format('DD-MM-YYYY') }</span>
      </div>
    ),
    [ProductOverviewTableColumn.Created]: (
      <div className={classNames('flex px-3 align-items-center w-full text-sm text-gray-700', {
        'border-green-500 border-y-1': isSelected
      })}
      >
        <span className="line-height-1">{ value?.createdAt && moment(new Date(value?.createdAt)).format('DD-MM-YYYY') }</span>
      </div>
    ),
    [ProductOverviewTableColumn.TagsDescription]: (
      <div className={
        classNames('flex px-3 align-items-center w-full border-round-right-lg text-sm text-gray-700', {
          'border-green-500 border-1 border-left-none': isSelected
        })
      }
      >
        <TagsDescriptionWrapper>
          {
            value?.tags?.map((tag: any) => (
              <Tag
                data-testid={`overview-tag-${tag.id}`}
                key={tag.id}
                style={{
                  color: tag.color,
                  backgroundColor: `${tag.color}1a`
                }}
                value={tag.name}
                className="mt-1 mr-1 h-1rem font-light text-xs px-1 py-0"
              />
            ))
          }
          { value?.description }
        </TagsDescriptionWrapper>
      </div>
    )
  })
  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0"
      onMouseEnter={() => onRowMouseEnter(value?.id)}
      onMouseLeave={() => onRowMouseLeave()}
    >
      { getBodyTemplate()[column] }
    </div>
  )
}
