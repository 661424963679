import React, { useContext } from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faXmarkLarge } from '@fortawesome/pro-regular-svg-icons'

import { TImpactDatasetContext } from '../interface/impact-dataset.context'
import ImpactDatasetContext from '../provider/context/impact-dataset.context'
import { ImpactDatasetViewMode, ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { CompareDatasetsItemComponent } from './compare-datasets-item.component'
import { useDatasetNavigate } from '../hook/use-dataset-navigate'
import { updateNavigationStorageItemData } from '../../shared/util/navigation'
import { IReferenceProduct } from '../../model'
import DashboardContext from '../../dashboard/context/dashboard.context'

const OverlayPanelWrapper = styled.div`
  position: fixed;
  bottom: 6.375rem;
  left: 50%;
  width: 21.75rem;
  z-index: 100;
  background: white;
`

const ComparisonButtonWrapper = styled.div`
  position: fixed;
  bottom: 2.875rem;
  left: 50%;
  width: 21.75rem;
  height: 3rem;

  -webkit-box-shadow: rgb(255, 255, 255) 0px 25px 150px 90px;
  -moz-box-shadow: rgb(255, 255, 255) 0px 25px 150px 90px;
  box-shadow: rgb(255, 255, 255) 0px 25px 150px 90px;

  .p-disabled {
    opacity: 1;
    background: var(--gray-50);
    color: var(--gray-100);
    border-color: var(--gray-100);
  }
`

export const CompareDatasetsComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])

  const {
    enableElementaryFlowComparison = false,
    enableDatabaseSearchComparison = false,
    selectedDatasetViewType = ImpactDatasetViewType.DatabaseSearch,
    comparisonDatabaseSearchItems = [],
    comparisonElementaryFlowItems = [],
    updateImpactDataset
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { addToBackToList = () => {} } = useContext(DashboardContext)
  const { datasetNavigate, inventoryItemId } = useDatasetNavigate()

  const isDatabaseSearch = selectedDatasetViewType === ImpactDatasetViewType.DatabaseSearch
  const isElementaryFlow = selectedDatasetViewType === ImpactDatasetViewType.ElementaryFlow
  const comparisonDatasets = isDatabaseSearch ? comparisonDatabaseSearchItems : comparisonElementaryFlowItems
  const isComparisonEnabled = (isDatabaseSearch && enableDatabaseSearchComparison) || (isElementaryFlow && enableElementaryFlowComparison)

  const isCompareDatasetBtnDisabled = () => {
    if (isComparisonEnabled) {
      return comparisonDatasets.length < 2
    }
    return false
  }

  const openComparisonDetail = () => {
    if (!inventoryItemId) return

    const backLabel = isDatabaseSearch
      ? t('labels.databaseSearch', { ns: 'impact-dataset' })
      : t('labels.elementaryFlow', { ns: 'impact-dataset' })

    addToBackToList(backLabel)
    updateNavigationStorageItemData(inventoryItemId, { comparisonDatasets })
    datasetNavigate(selectedDatasetViewType, ImpactDatasetViewMode.Compare, false)
  }

  const handleCompareDatabaseSearch = () => {
    if (isDatabaseSearch && !enableDatabaseSearchComparison) {
      updateImpactDataset({ enableDatabaseSearchComparison: true })
    } else if (isElementaryFlow && !enableElementaryFlowComparison) {
      updateImpactDataset({ enableElementaryFlowComparison: true })
    } else {
      openComparisonDetail()
    }
  }

  const clearComparison = () => {
    updateImpactDataset(isDatabaseSearch ? { enableDatabaseSearchComparison: false, comparisonDatabaseSearchItems: [] } : { enableElementaryFlowComparison: false, comparisonElementaryFlowItems: [] })
  }

  const removeDatasetItem = (datasetItem: IReferenceProduct) => {
    const selectedIndex = comparisonDatasets.findIndex((item: IReferenceProduct) => item.id === datasetItem.id)
    if (selectedIndex >= 0) {
      const updatedComparisonItems = [ ...comparisonDatasets ]
      updatedComparisonItems.splice(selectedIndex, 1)
      updateImpactDataset(isDatabaseSearch ? { comparisonDatabaseSearchItems: updatedComparisonItems } : { comparisonElementaryFlowItems: updatedComparisonItems })
    }
  }

  return (
    <>
      { isComparisonEnabled && (
        <OverlayPanelWrapper className="flex flex-column shadow-2 border-round-sm bg-white p-3">
          <div className="flex align-items-center w-full font-medium">
            <FontAwesomeIcon data-testid="header-label-icon" icon={faDatabase} className="text-base" />
            <div data-testid="filter-header-label" className="flex flex-grow-1 align-items-center mx-2 font-medium">
              { t('labels.datasetComparisonPanel', { context: 'title', count: comparisonDatasets.length }) }
            </div>
            <FontAwesomeIcon data-testid="clear-comparison" onClick={clearComparison} icon={faXmarkLarge} className="text-base cursor-pointer hover:text-primary-500" />
          </div>
          { comparisonDatasets.length > 0 && (
            <div data-testid="compare-dataset-wrapper" className="flex flex-column w-full align-items-center pt-3">
              <ul className="w-full">
                { comparisonDatasets?.map((datasetItem, index) => (
                  <li
                    data-testid={`compare-dataset-${index}`}
                    key={`compare-dataset-${index}`}
                    className={classNames('flex align-items-center gap-5 w-full py-2 border-gray-100', {
                      'border-bottom-1': index !== comparisonDatasets.length - 1
                    })}
                  >
                    <CompareDatasetsItemComponent datasetItem={datasetItem} removeDatasetItem={removeDatasetItem} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </OverlayPanelWrapper>
      )}

      <ComparisonButtonWrapper className="flex justify-content-center align-items-center">
        <Button
          data-cy="compare-datasets-button"
          data-testid="compare-datasets-button"
          label={t('labels.compare', { context: 'datasets' })}
          className="p-button-primary w-full h-3rem"
          disabled={isCompareDatasetBtnDisabled()}
          onClick={handleCompareDatabaseSearch}
        />
      </ComparisonButtonWrapper>
    </>
  )
}
