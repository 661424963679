import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { FacetValue } from '../../../../__generated__/graphql'

type TFilterCheckboxListProps = {
  itemsList: FacetValue[],
  selectedItems?: string[],
  onItemChange: (newSelectedItems: string[]) => void
}
export const FilterCheckboxList = ({ itemsList = [], selectedItems = [], onItemChange }: TFilterCheckboxListProps) => {
  const onItemToggle = (itemName: string) => {
    let newValues = selectedItems
    if (selectedItems.includes(itemName)) {
      newValues = selectedItems.filter(name => name !== itemName)
    } else {
      newValues = [ ...selectedItems, itemName ]
    }

    onItemChange(newValues)
  }

  const isChecked = (itemName: string) => selectedItems.includes(itemName)
  const showCounts = selectedItems.length === 0

  const getLabel = (item: FacetValue) => {
    const isSelected = isChecked(item.name)
    return (
      <div className="text-sm text-grey-500">
        <span>{item.name}</span>
        {(showCounts || isSelected) && <span className="text-xs ml-1">({item.count})</span>}
      </div>
    )
  }

  return (
    <div data-testid="filter-list-wrapper" className="flex flex-column w-full">

      <ul className="w-full">
        { itemsList.map((item, index) => (
          <li
            data-testid={`filter-item-${index}`}
            key={`filter-item-${index}`}
            className="flex align-items-center gap-2 w-full py-1"
          >
            <Checkbox
              inputId={item.name}
              name={`filter-checkbox-${index}`}
              value={item}
              onChange={() => onItemToggle(item.name)}
              data-testid={`filter-checkbox-${index}`}
              checked={isChecked(item.name)}
            />
            <label
              htmlFor={item.name}
              className="line-height-2 text-sm text-gray-800 cursor-pointer"
            >
              {getLabel(item)}
            </label>
          </li>
        )) }
      </ul>
    </div>
  )
}
