/* eslint-disable no-cond-assign */
const findUrlIndices = (text: string): { url: string, start: number, end: number }[] => {
  let match
  const urlPattern = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  const urlIndices = []
  while ((match = urlPattern.exec(text)) !== null) {
    const start = match.index
    const end = start + match[0].length
    urlIndices.push({ url: match[0], start, end })
  }

  return urlIndices
}
/* eslint-disable no-cond-assign */

const getTextNode = (text:string, start: number, end?: number) => {
  const subText = text.substring(start, end)
  const subTextSpan = document.createElement('span')
  subTextSpan.appendChild(document.createTextNode(subText))
  return subTextSpan
}

const getLinkNode = (url: string) => {
  const link = document.createElement('a')
  link.href = url
  link.textContent = url
  link.target = '_blank'
  link.style.wordBreak = 'break-all'

  return link
}

export const generateFormatContent = (container: HTMLDivElement, description: string = '', isEcoinventDatabase: boolean = false) => {
  let text = description
  if (isEcoinventDatabase) {
    text = description.replace(/;(?!\s)/g, '\n')
  }

  const urlIndices = findUrlIndices(text)

  if (urlIndices.length === 0) {
    container.appendChild(getTextNode(text, 0))
  }

  urlIndices.forEach((item, index) => {
    let textStart = 0
    if (index > 0) {
      textStart = urlIndices[index - 1].end
    }

    container.appendChild(getTextNode(text, textStart, item.start))
    container.appendChild(getLinkNode(item.url))

    if (index === urlIndices.length - 1) {
      container.appendChild(getTextNode(text, item.end))
    }
  })
}

// formatTimePeriodDescription function is used to format the time period description
export const formatTimePeriodDescription = (timeFrom: string = '', timeUntil: string = '', timeDescription: string = '') => {
  if (!timeFrom && !timeUntil) {
    return ''
  }

  if (timeFrom.split('-').length !== 3 || timeUntil.split('-').length !== 3) {
    return ''
  }

  // take the year from the timeFrom and timeUntil when dd-mm-yyyy format
  const yearFrom = timeFrom.split('-')[2]
  const yearUntil = timeUntil.split('-')[2]

  if (!timeDescription || timeDescription.toLowerCase() == 'none') {
    return `${yearFrom}-${yearUntil}`
  }

  return `${yearFrom}-${yearUntil}. ${timeDescription}`
}

// formatGeographyDescription function is used to format the geography description
export const formatGeographyDescription = (location: string = '', geography: string = '') => {
  if (!location) {
    return ''
  }

  if (!geography) {
    return location
  }

  return `${location}. ${geography}`
}

// formatDatabaseCategoryPath function is used to format the category full trail
export const formatDatabaseCategoryPath = (container: HTMLDivElement, databaseCategoryPath: string = '') => {
  if (!databaseCategoryPath) {
    return ''
  }

  const wrapperElement = container.getElementsByClassName('database-category-path')
  if (wrapperElement.length > 0) {
    container.removeChild(wrapperElement[0])
  }

  const wrapperDiv = document.createElement('div')
  wrapperDiv.setAttribute('class', 'database-category-path')

  const parts = databaseCategoryPath.split(' > ')

  parts.forEach(part => {
    const colonIndex = part.indexOf(':')

    if (colonIndex !== -1) {
      const boldPart = part.substring(0, colonIndex + 1)
      const rest = part.substring(colonIndex + 1).trim()
      const boldSpan = document.createElement('b')
      boldSpan.appendChild(document.createTextNode(boldPart))
      wrapperDiv.appendChild(boldSpan)
      wrapperDiv.appendChild(document.createTextNode(` ${rest}`))
    } else {
      wrapperDiv.appendChild(document.createTextNode(part))
    }
    if (parts.indexOf(part) !== parts.length - 1) {
      wrapperDiv.appendChild(document.createTextNode(' > '))
    }
  })

  container.appendChild(wrapperDiv)
}
