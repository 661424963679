import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

import { formatDatabaseCategoryPath, generateFormatContent } from '../util/content'

const ContentWrapper = styled.div`
  white-space: pre-wrap;
`

type TDetailContentComponentProps = {
  content?: string,
  header?: string,
  isEcoinventDatabase?: boolean,
  isCategoryPath?: boolean,
  dataCy?: string
}
export const FormattedContentComponent = ({
  content, header, isEcoinventDatabase, isCategoryPath = false, dataCy
}: TDetailContentComponentProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef?.current && content) {
      if (isCategoryPath) {
        formatDatabaseCategoryPath(containerRef.current, content)
      } else {
        generateFormatContent(containerRef.current, content, isEcoinventDatabase)
      }
    }
  }, [ containerRef?.current ])

  return (
    <>
      { content && (
        <div data-cy={dataCy} className="flex flex-column w-full gap-2">
          { header && <div className="block w-full text-sm font-medium">{header}</div> }
          <ContentWrapper data-testid="content-wrapper" ref={containerRef} className="block w-full text-sm" />
        </div>
      )}
    </>
  )
}
