import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client'
import { PRODUCT_WITH_IMPACT } from '../../../graphql/query';
import { IDashboardContext } from '../../../shared/interface/workspace-context-type';
import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IProduct } from '../../../model';
import { ProductDetailPanelLayout } from '../../layout/product-detail-panel.layout';

type TProductDetailPanelContainerProps = {
  productId: string
}
export const ProductDetailPanelContainer = ({ productId }: TProductDetailPanelContainerProps) => {
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext);

  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    variables: { id: productId },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      const { product = null } = productWithImpactData?.productWithImpact || {}
      updateDashboard({ selectedEntity: product as IProduct })
    }
  }, [ loadingProductWithImpact, productWithImpactData ]);

  return (
    <>
      { !loadingProductWithImpact && <ProductDetailPanelLayout /> }
    </>
  );
}
