import { useContext, useEffect } from 'react'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { Navigation } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { InventoryViewType } from '../../product/enum/inventory-view-type'

import { AnalysisMode } from '../enum/analysis'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import ProductDetailContext from '../../product/provider/context/product-detail.context'
import { TProductDetailContext } from '../../product/interface/product-detail-context.type'

const trackProductNavigation = (activeTab?: InventoryViewType) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const activeIndex = Object.values(InventoryViewType).findIndex(viewType => viewType === activeTab) || 0
  const TabEventTypes: TrackEventType[] = [
    TrackEventType.VIEWED_MODEL,
    TrackEventType.VIEWED_LIFECYCLE_TABLE,
    TrackEventType.VIEWED_LIFECYCLE_DONUT_CHART
  ]
  trackEventInSegment(TabEventTypes[activeIndex])
}

export const useProductAnalysisNavigate = (newAnalysisMode?: AnalysisMode) => {
  const { productId: productIdFromURL } = useParams();
  const { space: { id: spaceId = null, slug: workspaceSlug = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {};
  const { account: { id: accountId = null } = {} } = useReactiveVar(selectedAccountVar) || {};

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const {
    secondaryEntityId,
    secondaryEntityType,
    analysisMode,
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext);
  const { addToBackToList = () => {}, removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext);
  const { updateProductDetail } = useContext<TProductDetailContext>(ProductDetailContext);

  const activeTab = searchParams.get('activeTab');
  const secondaryEntityIdParam = searchParams.get('id');
  const secondaryEntityTypeParam = searchParams.get('type');
  const selectedAnalysisMode = newAnalysisMode || analysisMode
  const comparisonMode = selectedAnalysisMode === AnalysisMode.Comparison;

  useEffect(() => {
    if (secondaryEntityIdParam) {
      updateAnalysis({ secondaryEntityId: secondaryEntityIdParam })
    }
  }, [ secondaryEntityIdParam ])

  useEffect(() => {
    const selectedInventoryViewType = activeTab ? activeTab as InventoryViewType : InventoryViewType.Model;
    trackProductNavigation(selectedInventoryViewType);
    updateProductDetail({ selectedInventoryViewType });
  }, [ activeTab ])

  const navigateBackToSource = () => {
    navigate(-1);
    removeFromBackToList();
  }

  const productAnalysisNavigate = ({
    selectedProductId,
    selectedSecondaryEntityId,
    selectedSecondaryEntityType,
    activeTab = InventoryViewType.Model,
    replace = true,
    backLabel,
  }: {
    selectedProductId?: string;
    selectedSecondaryEntityId?: string;
    selectedSecondaryEntityType?: string;
    activeTab?: InventoryViewType;
    replace?: boolean;
    backLabel?: string | null;
  } = {}) => {
    const productId = selectedProductId || productIdFromURL;
    const id = selectedSecondaryEntityId || secondaryEntityId || secondaryEntityIdParam;
    const type = selectedSecondaryEntityType || secondaryEntityType || secondaryEntityTypeParam;

    if (!accountId || !workspaceSlug || !productId || !id) return;

    const isOnProductComparison = isValid(matchPath(Navigation.ProductComparison, pathname));
    const isOnProductScenario = isValid(matchPath(Navigation.ProductScenario, pathname));
    const isSamePrimaryProduct = productId === productIdFromURL;
    const isSameSecondaryProduct = id === secondaryEntityIdParam;

    if (isSamePrimaryProduct && isSameSecondaryProduct && (isOnProductComparison || isOnProductScenario)) {
      setSearchParams({ activeTab, id, ...(type ? { type } : {}) }, { replace });
      return;
    }

    if (backLabel) addToBackToList(backLabel);

    const commonPathParams = { accountId, workspaceSlug, productId };
    const navigateParam = comparisonMode
      ? { pathname: generatePath(Navigation.ProductComparison, commonPathParams), search: `?activeTab=${activeTab}&type=${type}&id=${id}` }
      : { pathname: generatePath(Navigation.ProductScenario, commonPathParams), search: `?activeTab=${activeTab}&id=${id}` };

    navigate(navigateParam);
  };

  return {
    spaceId,
    accountId,
    workspaceSlug,
    activeTab,
    productAnalysisNavigate,
    navigateBackToSource,
  };
}
