import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faGrid2 } from '@fortawesome/pro-regular-svg-icons'

import { IDashboardContext } from '../../shared/interface/workspace-context-type';
import DashboardContext from '../../dashboard/context/dashboard.context';
import { IProduct } from '../../model';
import { ProductLabel } from '../../shared/enum';
import { DetailPanelHeaderComponent } from '../../shared/component/general/detail-panel-header.component';

export const ProductDetailPanelLayout = () => {
  const { selectedEntity, updateDashboard } = useContext<IDashboardContext>(DashboardContext);
  const { labels = [], name } = selectedEntity as IProduct || {}
  const [ productLabel ] = labels
  const isProduct = productLabel?.id === ProductLabel.Product

  const afterCloseDetailPanel = () => {
    updateDashboard({ selectedEntity: null })
  }

  return (
    <div className="flex flex-column w-full h-full">
      <div className="flex w-full h-5rem">
        {
          name && (
            <DetailPanelHeaderComponent
              title={name}
              afterCloseDetailPanel={afterCloseDetailPanel}
              icon={<FontAwesomeIcon icon={isProduct ? faTag : faGrid2} className="text-xl" />}
            />
          )
        }
      </div>
      <div className="flex flex-grow-1"></div>
    </div>
  );
}
