import React, { useContext } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { classNames } from 'primereact/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { ApiOutlined } from '@ant-design/icons'

import { TLifecycleContext } from '../../interface/lifecycle-context.type'
import LifecycleContext from '../../provider/context/lifecycle.context'
import { MenuLifecycleContainer } from '../../container/menu/menu-lifecycle.container'
import { ILifecycle } from '../../../model'
import { LifecycleOverviewTableColumn, TLifecycleOverviewTableColumn } from '../../../shared/enum'

const LifecycleName = styled.div`
  width: calc(100% - 6.5rem);
  max-height: 4rem;
  overflow: hidden;
  line-height: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const LifecycleIcon = styled.div`
  background: var(--primarydark-400);
`

const LifecycleMenu = styled.div`
  width: 4.375rem;
  height: 100%;
`

export type ColumnValue = {
  lifecycle: ILifecycle,
  isMouseOver?: boolean
}

export type TableBodyTemplateComponentProps = {
  onRowMouseEnter?: Function,
  onRowMouseLeave?: Function,
  column?: TLifecycleOverviewTableColumn,
  value: ColumnValue
}

export const TableBodyTemplateComponent = ({
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  column = LifecycleOverviewTableColumn.Name,
  value
}: TableBodyTemplateComponentProps) => {
  const { selectedLifecycle } = useContext<TLifecycleContext>(LifecycleContext)

  const { lifecycle, isMouseOver = false } = value
  const {
    id, updatedAt, createdAt, description, product
  } = lifecycle
  const isSelected = selectedLifecycle?.id === id

  const getBodyTemplate = () => ({
    [LifecycleOverviewTableColumn.Name]: (
      <div className={classNames('flex align-items-center p-2 py-0 w-full border-round-left-lg', {
        'border-green-500 border-1 border-right-none': isSelected
      })}
      >
        <LifecycleIcon
          data-testid="lifecycle-template-icon"
          className="flex align-items-center justify-content-center w-2rem h-2rem border-round-md bg-primary-50 text-primary-500"
        >
          <FontAwesomeIcon icon={faRecycle} className="text-xl" />
        </LifecycleIcon>
        <LifecycleName className="surface-overlay align-items-center px-2 text-sm text-gray-700">
          { value?.lifecycle?.name }
        </LifecycleName>
        <LifecycleMenu className="flex align-items-center justify-content-start">
          <MenuLifecycleContainer {...{ lifecycle, isMouseOver, isSelected }} />
        </LifecycleMenu>
      </div>
    ),
    [LifecycleOverviewTableColumn.LastChanged]: (
      <div className={classNames('flex px-3 align-items-center w-full text-sm text-gray-700', {
        'border-green-500 border-y-1': isSelected
      })}
      >
        <span className="mt-1">{ updatedAt && moment(new Date(updatedAt)).format('DD-MM-YYYY') }</span>
      </div>
    ),
    [LifecycleOverviewTableColumn.Created]: (
      <div className={classNames('flex px-3 align-items-center w-full text-sm text-gray-700', {
        'border-green-500 border-y-1': isSelected
      })}
      >
        <span className="mt-1">{ createdAt && moment(new Date(createdAt)).format('DD-MM-YYYY') }</span>
      </div>
    ),
    [LifecycleOverviewTableColumn.Description]: (
      <div className={classNames('flex flex-wrap align-items-center px-3 w-full border-round-right-lg', {
        'border-green-500 border-1 border-left-none': isSelected
      })}
      >
        { product && (
          <div className="w-full white-space-nowrap overflow-hidden text-sm text-overflow-ellipsis line-height-1">
            <ApiOutlined className="text-md mt-1 mr-1" />
            {' '}
            { product.name }
          </div>
        )}
        { description && (
          <div className="w-full white-space-nowrap mb-1 overflow-hidden text-sm text-overflow-ellipsis line-height-1">
            { description }
          </div>
        )}
      </div>
    )
  })
  return (
    <div
      className="flex flex-none w-full h-full p-0 m-0"
      onMouseEnter={() => onRowMouseEnter(id)}
      onMouseLeave={() => onRowMouseLeave()}
    >
      { getBodyTemplate()[column] }
    </div>
  )
}
