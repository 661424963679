import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TabMenu } from 'primereact/tabmenu'

import { InventoryViewType } from '../../enum/inventory-view-type'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { ChangeImpactCategoryContainer } from '../../container/change-impact-category.container'
import { useProductNavigate } from '../../hook/use-product-navigate'

export const TaskbarDetailComponent = () => {
  const { t } = useTranslation('common')
  const { productNavigate } = useProductNavigate()
  const { selectedInventoryViewType } = useContext<TProductDetailContext>(ProductDetailContext)

  const productMenuItems = [
    { label: t('labels.tab', { context: 'model' }), className: 'cy-tab-model' },
    { label: t('labels.tab', { context: 'table' }), className: 'cy-tab-table' },
    { label: t('labels.tab', { context: 'barChart' }), className: 'cy-tab-barchart' },
    { label: t('labels.tab', { context: 'donutChart' }), className: 'cy-tab-doughnut' }
  ]

  const handleTabChange = (event: any) => {
    const { index = 0 } = event || {}
    const activeTab = Object.values(InventoryViewType)[index]
    productNavigate({ activeTab })
  }

  const getActiveIndex = (selectedViewType?: InventoryViewType) => Object.values(InventoryViewType).findIndex(viewType => viewType === selectedViewType) || 0

  return (
    <div className="flex align-items-center justify-content-start w-full">
      <div className="flex flex-grow-1">
        <TabMenu model={productMenuItems} activeIndex={getActiveIndex(selectedInventoryViewType)} onTabChange={(event: any) => handleTabChange(event)} />
      </div>
      <div className="flex flex-none h-full mb-1">
        <ChangeImpactCategoryContainer />
      </div>
    </div>
  )
}
