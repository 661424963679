import React, { useContext } from 'react'
import html2canvas from 'html2canvas'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons'

import { useTranslation } from 'react-i18next'
import { getDownloadFilename, isValid } from '../../../shared/util/tools'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'

interface BarViewDownloadProps {
  containerRef?: React.RefObject<HTMLDivElement | null>
}
export const BarViewDownloadComponent = ({ containerRef }: BarViewDownloadProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    productInventory: { product = null } = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const isDownloadEnabled = () => isValid(product?.referenceProduct) || product?.hasInventory

  const downloadChart = async () => {
    if (product && containerRef?.current) {
      trackEventInSegment(TrackEventType.DOWNLOADED_IMAGE_BAR_CHART)
      const canvas = await html2canvas(containerRef?.current, {
        onclone: (cloneElement: Document) => {
          const updateClassList = (domElement?: Element | null, add: string = '', remove: string = '',) => {
            domElement?.classList.remove(remove)
            domElement?.classList.add(add)
          }
          updateClassList(cloneElement.querySelector('.png-footer-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.png-header-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.bar-view-wrapper'), 'bg-white', 'bar-view-shadow')
        },
        backgroundColor: 'white',
        removeContainer: true,
        allowTaint: true,
        useCORS: true
      })
      const image = canvas.toDataURL('image/png', 1.0)
      const a = document.createElement('a')
      a.href = image
      a.download = getDownloadFilename({ value: `Bar chart for ${product?.name}`, extension: 'png' })
      a.click()
    }
  }

  return (
    <>
      { !isDownloadEnabled() && <Tooltip className="w-15rem" target=".disabled-barChart-download" position="bottom" disabled={isDownloadEnabled()} /> }

      <div className="flex disabled-barChart-download" data-pr-tooltip={t('messages.description', { context: 'addItemsOrImpact', ns: 'product' })}>
        <Button
          onClick={downloadChart}
          disabled={!isDownloadEnabled()}
          data-testid="download-barChart-button"
          label={t('actions.download', { ns: 'common' })}
          icon={<FontAwesomeIcon icon={faArrowDownToBracket} className="mr-2" />}
          className="p-button p-component p-button-outlined p-button-plain text-sm h-2rem mx-1 px-2"
        />
      </div>
    </>
  )
}
