import { gql } from '../../../__generated__'

export const PRODUCT_WITH_IMPACT = gql(`
  query productWithImpact($id: ID!){
    productWithImpact(id: $id) {
      product {
        id
        name
        type
        unit
        hasInventory
        description
        module {
          code
        }
        labels {
          id
        }
        tags {
          id
          name
          color
        }
        customImpacts {
          amount
          impactCategoryID
          impactMethodID
          excludeLT
        }
        referenceProduct {
          ...referenceProduct
        }
        productProperties {
          referenceProperty {
            id
            name
          }
          conversionFactor
          referenceUnit {
            id
            name
          }
        }
        scenarios{
          id
          name
        }
      }
      impact {
        amount
        unit
        status
      }
    }
  }
`)

export const PRODUCT_EXPORT = gql(`
  query productExport($id: ID!) {
    productExport(id: $id) {
        level
        name
        description
        amount
        unit
        label
        datasetID
        datasetName
        type
        area
        areaTime
        bioticProdOcc
        bioticProdTransf
        duration
        energy
        energyAreaTime
        energyMassTime
        erosionOcc
        erosionTransf
        transport
        grossCalorific
        groundwaterOcc
        groundwaterTransf
        guestNight
        itemsLength
        length
        lengthTime
        marketValue
        mass
        massTime
        mechFiltrationOcc
        mechFiltrationTransf
        netCalorific
        normalVolume
        numItems
        personTransport
        physFiltrationOcc
        physFiltrationTransf
        radioactivity
        vehiculeTransport
        volume
        volumeLength
        volumeTime
    }
  }
`)

