import React, { useContext, useEffect } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CreateProductComponent } from '../component/product/create-product.component'

import { selectedWorkspaceVar } from '../../../graphql/cache'
import { ProductLabel } from '../enum'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { CREATE_PRODUCT } from '../../graphql/mutation'
import { useHandleMutationResponse } from '../hook/use-handle-mutation-response'

type TCreateProductContainerProps = {
  afterCreateProduct: (param: string) => void,
  folderID?: string,
  label?: ProductLabel
}

export const CreateProductContainer = ({
  afterCreateProduct,
  folderID = '',
  label = ProductLabel.Product
}: TCreateProductContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}

  const { showCreateProductDialog = false, updateDashboard } = useContext(DashboardContext)
  const [
    createProduct,
    {
      error: failedCreatingProduct,
      data: createProductData = {},
      loading: productCreating
    }
  ] = useMutation(CREATE_PRODUCT)
  const productCreated = createProductData?.createProduct
  useEffect(() => {
    if (!productCreating && (productCreated || failedCreatingProduct)) {
      handleResponse({
        error: failedCreatingProduct,
        data: productCreated,
        callback: createProductCallback,
        successToastDetail: { label: 'messages.success', context: 'createProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createProduct' }
      })
    }
  }, [ productCreating, productCreated, failedCreatingProduct ])

  const closeCreateProductDialog = () => {
    updateDashboard({ showCreateProductDialog: false })
  }

  const handleCreateProduct = (name: string, unit: string) => {
    spaceID && createProduct({
      variables: {
        name, spaceID, folderID, labels: [ label ], unit
      }
    })
  }

  const createProductCallback = () => {
    productCreated?.id && afterCreateProduct(productCreated.id)
    closeCreateProductDialog()
  }

  return (
    <>
      <CreateProductComponent
        label={label}
        handleCreateProduct={handleCreateProduct}
        closeCreateProductDialog={closeCreateProductDialog}
        showCreateProductDialog={showCreateProductDialog}
      />
    </>
  )
}
