import React, {
  useRef, useContext, useEffect
} from 'react'

import { Tree } from 'primereact/tree'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons'

import { TreeItemComponent } from './tree-item.component'
import { TInventoryTreeItem } from '../../../shared/util/transform'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TreeHeaderComponent } from './tree-header.component'
import { store } from '../../../../configureStore'
import { showProductInDetailsPanelSaga } from '../../../../redux/sagas/product.saga'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import { setIsCreateNewInventoryItemAction, setIsDetailPanelReadonlyV1Action } from '../../../../redux/actions/flags.actions'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { isValid } from '../../../shared/util/tools'
import { TreeImpactComponent } from './tree-impact.component'
import FeatureContext from '../../../dashboard/context/feature.context'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { Feature } from '../../../shared/enum/feature'
import { useIsMounted } from '../../../shared/hook/use-is-mounted'
import { ProductDetailPanelContainer } from '../../container/detail-panel/product-detail-panel.container'

type TTreeWrapperProps = {
  theme?: any,
  analysisMode?: AnalysisMode
}

const TreeComponentWrapper = styled.div<TTreeWrapperProps>`
  width: 100%;

  .p-tree {
    width: 100%;
    border: none;
    background: none;
    padding: ${({ analysisMode }: TTreeWrapperProps) => (isValid(analysisMode) ? '0.5rem 1rem' : '0 2rem')};

    .p-treenode-content {
      padding: 0;
      background: var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.background});
      border-radius: 0.5rem !important;
      padding: 0.25rem !important;
      align-items: flex-start;

      .p-tree-toggler {
        width: 1.5rem !important;
        height: 1.5rem !important;
        margin: 0 0.25rem 0 0.5rem !important;
      }

      &:hover {
        background: var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.background}) !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }

      &:focus,
      .p-tree-toggler:focus {
        box-shadow: none !important;
      }

      &.p-highlight {
        border: 1px solid var(${({ theme }: TTreeWrapperProps) => theme.inventoryTree.highlightBorder});
      }
    }

    .p-tree-container, .p-tree-header {
      padding: 0 0.25rem;
    }

    .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
      display: none;
    }

    .p-treenode-children .p-treenode-content  {
      background: var(--primarydark-50) !important;
    }

    .p-treenode {
      padding: 0.25rem 0;
    }

    .p-treenode-children {
      padding-top: 0.25rem !important;

      li.p-treenode:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.notSelectable {
    .p-treenode-content {
      cursor: default !important;
      box-shadow: none !important;
    }
  }
`

export type TTreeComponentProps = {
  inventoryTree?: TInventoryTreeItem[],
  showImpact?: boolean,
}
export const TreeComponent = ({
  inventoryTree = [],
  showImpact
}: TTreeComponentProps) => {
  const toast = useRef<Toast>(null)
  const {
    analysisMode,
    treeIsSecondary,
    selectedKey,
    expandedKeys = [],
    updateProductDetail,
    setLifecycleSelectedKeyAndPhase = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)

  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableProductDetailPanel = isFeatureEnabled(Feature.ProductDetailPanel) || false

  const {
    openSidebar = () => {},
    closeSidebar = () => {}
  } = useContext(DashboardContext)
  const isMounted = useIsMounted()
  const componentIsMounted = isMounted()

  useEffect(() => {
    if (componentIsMounted) {
      closeProductDetailSidebar()
    }
  }, [ componentIsMounted ])

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  useEffect(() => {
    if (!isDetailsPanelOpen) {
      updateProductDetail({
        selectedKey: null,
        selectedAction: null,
        selectedProduct: null,
        selectedInventoryKey: null,
        editAmountMode: false
      })
    }
  }, [ isDetailsPanelOpen ])

  const togglerTemplate = (node: any, options: any) => {
    const { containerClassName, onClick } = options
    return (
      <button data-testid={`toggler-button-${node.key}`} type="button" className={containerClassName} onClick={onClick}>
        { node.expanded ? <FontAwesomeIcon icon={faCaretDown} className="text-base" /> : <FontAwesomeIcon icon={faCaretRight} className="text-base" /> }
      </button>
    )
  }

  const nodeTemplate = (node: any, options: any) => {
    const { onClick: onNodeClick } = options?.props || {}
    return showImpact ? (
      <TreeImpactComponent node={node} />
    ) : (
      <TreeItemComponent node={node} selectedKey={selectedKey} onNodeClick={onNodeClick} />
    )
  }

  const headerTemplate = () => <TreeHeaderComponent />

  const onSelectionChange = (e: any) => {
    if (e.value === selectedKey || showImpact) {
      updateProductDetail({ selectedKey: null })
    } else {
      updateProductDetail({ selectedKey: e.value })
    }
  }

  const onExpand = (event: any) => {
    const { node } = event

    updateProductDetail({ expandedKeys: [ ...expandedKeys, node.key ] })
  }

  const onCollapse = (event: any) => {
    const { node } = event
    const updatedExpandedKeys = expandedKeys.filter((key: string) => key !== node.key)
    updateProductDetail({ expandedKeys: [ ...updatedExpandedKeys ] })
  }

  const closeProductDetailSidebar = () => {
    updateProductDetail({
      selectedKey: null,
      selectedAction: null,
      selectedProduct: null,
      selectedInventoryKey: null,
      editAmountMode: false
    })
    closeSidebar()
  }

  const onSidebarHide = (event: any) => {
    const targetDOM = event.target as HTMLElement

    if (!targetDOM.dataset.treeItem) {
      closeProductDetailSidebar()
    }
  }

  const openProductDetailSidebar = (node: any) => {
    const { data: { inventoryItem: { product } } } = node
    product.id && openSidebar(<ProductDetailPanelContainer productId={product.id} />, onSidebarHide)
  }

  const openLegacyProductDetailSidebar = (node: any) => {
    const { data: { inventoryItem: { product } } } = node
    store.dispatch(setIsCreateNewInventoryItemAction(false))

    if (node.key === selectedKey) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }

    setLifecycleSelectedKeyAndPhase(node.key)
    const scenarioMode = analysisMode === AnalysisMode.Scenario
    store.dispatch(setIsDetailPanelReadonlyV1Action(scenarioMode && !treeIsSecondary))
    store.dispatch(showProductInDetailsPanelSaga(product.id))
  }

  const onNodeClick = (event: any) => {
    if (showImpact) return null

    event.originalEvent.stopPropagation()
    const { node } = event

    updateProductDetail({
      selectedInventoryKey: node.key,
      editAmountMode: false,
      selectedAction: null
    })

    enableProductDetailPanel
      ? openProductDetailSidebar(node)
      : openLegacyProductDetailSidebar(node)
  }

  return (
    <TreeComponentWrapper
      className={classNames({ notSelectable: showImpact })}
      data-testid="tree-component-wrapper"
      analysisMode={analysisMode}
    >
      <Toast ref={toast} />
      <Tree
        value={inventoryTree}
        header={headerTemplate}
        togglerTemplate={togglerTemplate}
        nodeTemplate={nodeTemplate}
        selectionMode="single"
        selectionKeys={selectedKey}
        onNodeClick={(event: any) => onNodeClick(event)}
        onExpand={(event: any) => onExpand(event)}
        onCollapse={(event: any) => onCollapse(event)}
        onSelectionChange={onSelectionChange}
      />
    </TreeComponentWrapper>
  )
}
