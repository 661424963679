import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { OverlayPanel } from 'primereact/overlaypanel'
import classNames from 'classnames'
import { createGlobalStyle } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { InputText } from 'primereact/inputtext'
import { useTranslation } from 'react-i18next'

import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { DatasetSortField, FacetValue, SortingOrder } from '../../../../__generated__/graphql'
import { SortTogglerComponent } from './sort-toggler.component'
import { GeographyFilterListComponent } from './geography-filter-list.component'

const GlobalStyle = createGlobalStyle`
  .p-inputtext {
    border-color: var(--gray-100) !important;
  }
  .p-inputtext::placeholder {
    color: var(--gray-300);
    opacity: 1; /* Firefox */
  }
  .p-inputtext:focus {
    box-shadow: none !important;
  }
  .geography-filter-panel {
    max-width: 17rem;

    .p-dropdown-item {
      white-space: normal;
    }
  }
`

type TGeographyFilterComponentProps = {
  label?: string
  tableHeaderId?: string
}

export const GeographyFilterComponent = ({
  label = '',
  tableHeaderId = ''
}: TGeographyFilterComponentProps) => {
  const {
    locationRegionList = [],
    locationList = [],
    selectedLocationRegions = [],
    selectedLocations = [],
    selectedSortBy,
    selectedSortOrder,
    updateFilterValue
  } = useContext(ImpactDatasetContext)
  const overlayPanel = useRef<OverlayPanel>(null)
  const isSortByLocation = selectedSortBy === DatasetSortField.Location
  const { t } = useTranslation([ 'impact-dataset' ])

  const [ searchValue, setSearchValue ] = useState<string>('')
  const [ filteredLocationList, setFilteredLocationList ] = useState<FacetValue[]>([])
  const [ orderedRegionLocationList, setOrderedRegionLocationList ] = useState<FacetValue[]>([])

  useEffect(() => {
    if (searchValue) {
      updateFilterValue({ selectedLocationRegions: [] })
      filterLocationOnSearch()
    }
  }, [ searchValue, locationList ])

  useEffect(() => {
    if (locationRegionList.length > 0) orderLocationRegion()
  }, [ locationRegionList ])

  const filterLocationOnSearch = () => {
    const filteredList = locationList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    filteredList.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA === 'global') return -1;
      if (nameB === 'global') return 1;

      const startsWithA = nameA.startsWith(searchValue.toLowerCase());
      const startsWithB = nameB.startsWith(searchValue.toLowerCase());

      if (startsWithA && !startsWithB) return -1;
      if (!startsWithA && startsWithB) return 1;

      return nameA.localeCompare(nameB);
    })
    setFilteredLocationList(filteredList)
  }

  const orderLocationRegion = () => {
    const orderedList = [ ...locationRegionList ].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA === 'global') return -1;
      if (nameB === 'global') return 1;
      return nameA.localeCompare(nameB);
    })
    setOrderedRegionLocationList(orderedList)
  }

  const hidePanel = () => {
    overlayPanel?.current?.hide()
  }

  const handleSortingChange = (sortOrder: any) => {
    const updatedValues = {
      selectedSortBy: sortOrder ? DatasetSortField.Location : undefined,
      selectedSortOrder: sortOrder || undefined
    }

    updateFilterValue(updatedValues)
    hidePanel()
  }

  const onListChange = (event: any) => {
    const isSearchListShown = selectedLocations.length > 0 || searchValue
    const selectedList = isSearchListShown ? selectedLocations : selectedLocationRegions
    const selectedListNames = [ ...selectedList ]
    const selectedListIndex = selectedListNames.findIndex(name => name === event.value.name)

    if (event.checked) {
      selectedListNames.push(event.value.name)
    } else {
      selectedListNames.splice(selectedListIndex, 1)
    }

    updateFilterValue({
      selectedLocationRegions: isSearchListShown ? [] : selectedListNames,
      selectedLocations: isSearchListShown ? selectedListNames : []
    })

    if (isSearchListShown && selectedListNames.length === 0) filterLocationOnSearch()
  }

  const onHidePanel = () => {
    setSearchValue('')
    setFilteredLocationList(locationList.filter(item => selectedLocations.includes(item.name)))
  }

  const isSearchListShown = selectedLocations.length > 0 || searchValue

  return (
    <>
      <GlobalStyle />
      <OverlayPanel className="top-auto left-auto mt-5" appendTo={document.getElementById(tableHeaderId)} onHide={onHidePanel} ref={overlayPanel}>
        <div className="flex flex-column w-full">
          <div className="flex w-full  line-height-1 text-sm font-medium">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
            <div className="flex flex-grow-1 justify-content-end">
              <SortTogglerComponent
                onViewChange={handleSortingChange}
                selectedSortOrder={selectedSortBy === DatasetSortField.Location ? selectedSortOrder : undefined}
              />
            </div>
          </div>
          <div className="flex align-items-center pt-3 w-17rem">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon bg-white px-2 min-w-min">
                <i className="pi pi-search text-sm" />
              </span>
              <InputText
                data-cy="filter-location-input"
                data-testid="filter-location-input"
                value={searchValue}
                onChange={(event: any) => setSearchValue(event.target.value)}
                className="w-full h-2rem border-left-none text-xs px-1"
                placeholder={t('labels.searchForSpecificLocation')}
              />
            </div>
          </div>
          <div data-testid="property-filter-wrapper" className="flex flex-column w-full">
            <GeographyFilterListComponent
              itemList={isSearchListShown ? filteredLocationList : orderedRegionLocationList}
              selectedItemList={isSearchListShown ? selectedLocations : selectedLocationRegions}
              onChange={onListChange}
            />
          </div>
        </div>
      </OverlayPanel>
      <span
        data-testid="table-header-geography-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {

        })}
      >
        { label }
        { !isSortByLocation
          ? <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          : (
            <>
              { selectedSortOrder === SortingOrder.Asc && <FontAwesomeIcon data-testid="property-filter-asc" className="ml-1 text-sm" icon={faArrowDown} /> }
              { selectedSortOrder === SortingOrder.Desc && <FontAwesomeIcon data-testid="property-filter-desc" className="ml-1 text-sm" icon={faArrowUp} /> }
            </>
          )}
      </span>
    </>
  )
}
