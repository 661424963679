export enum EDatabaseSearchColumn {
  Selection = 'selection',
  DatasetName = 'datasetName',
  Geography = 'geography',
  Property = 'property',
  Database = 'database',
  Action = 'action'
}

export enum EElementaryFlowColumn {
  Selection = 'selection',
  DatasetName = 'datasetName',
  Compartment = 'compartment',
  SubCompartment = 'subCompartment',
  Property = 'property',
  Action = 'action'
}

export enum EComparisonDatasetColumn {
  Label = 'label',
  FirstDataset = 'firstDataset',
  SecondaryDataset = 'secondDataset',
  ThirdDataset = 'thirdDataset'
}

export enum EComparisonDatasetItem {
  DatasetName = 'datasetName',
  Geography = 'geography',
  ReferenceProduct = 'referenceProduct',
  Unit = 'unit',
  Database = 'database',
  Category = 'category',
  DatabaseCategoryPath = 'databaseCategoryPath',
  Compartment = 'compartment',
  SubCompartment = 'subCompartment',
  Technology = 'technology',
  Time = 'time',
  Description = 'description'
}
