import React, { useContext, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import html2canvas from 'html2canvas'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownToBracket, faFileExcel, faImage, faAngleDown
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import { REQUEST_PRODUCT_EXPORT } from '../../../graphql/mutation/product'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { getBrowserTimezone, getDownloadFilename, isValid } from '../../../shared/util/tools'

interface MenuDownloadTableContainerProps {
  tableRef?: React.RefObject<HTMLDivElement | null>
}

export const MenuDownloadTableContainer = ({ tableRef }: MenuDownloadTableContainerProps) => {
  const { t } = useTranslation([ 'product', 'common' ])

  const toast = useRef<Toast>(null)
  const menu = useRef<Menu>(null)
  const [ trackEventInSegment ] = getSegmentTrack()

  const {
    productInventory = {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { product } = productInventory

  const [
    requestProductExport,
    {
      error: failedRequest,
      data: requestProductExportData,
      loading: requestingProductExport
    }
  ] = useMutation(REQUEST_PRODUCT_EXPORT)
  const requestedProductExport = requestProductExportData?.requestProductExport

  useEffect(() => {
    if (!requestingProductExport && (requestedProductExport || failedRequest)) {
      replaceLabelCallback()
    }
  }, [ requestingProductExport, requestedProductExport, failedRequest ])

  const isDownloadEnabled = () => isValid(product?.referenceProduct) || product?.hasInventory

  const replaceLabelCallback = () => {
    try {
      if (failedRequest) {
        throw failedRequest
      } else if (requestedProductExport) {
        trackEventInSegment(TrackEventType.DOWNLOADED_EXCEL_RESULTS)
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'requestedProductExport', ns: 'product' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'requestedProductExport', ns: 'product' }), error?.graphQLErrors)
    }
  }

  const downloadTable = async () => {
    if (tableRef?.current) {
      trackEventInSegment(TrackEventType.DOWNLOADED_IMAGE_TABLE)
      const canvas = await html2canvas(tableRef?.current, {
        onclone: (cloneElement: Document) => {
          const updateClassList = (domElement?: Element | null, add: string = '', remove: string = '',) => {
            domElement?.classList.remove(remove)
            domElement?.classList.add(add)
          }
          updateClassList(cloneElement.querySelector('.png-footer-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.png-header-template'), 'flex', 'hidden')
          updateClassList(cloneElement.querySelector('.flat-view-wrapper'), 'bg-white', 'flat-view-shadow')
        },
        backgroundColor: 'white',
        removeContainer: true,
        allowTaint: true,
        useCORS: true
      })
      const image = canvas.toDataURL('image/png', 1.0)
      const a = document.createElement('a')
      a.href = image
      a.download = getDownloadFilename({ value: `Table view for ${product?.name}`, extension: 'png' })
      a.click()
    }
  }

  const downloadActionList = [
    {
      label: t('actions.download', { context: 'image', ns: 'common' }),
      icon: <FontAwesomeIcon icon={faImage} className="text-sm mr-1" />,
      command: downloadTable,
      template: MenuItemComponent
    },
    {
      label: t('actions.download', { context: 'impact' }),
      icon: <FontAwesomeIcon icon={faFileExcel} className="text-sm mr-1" />,
      command: () => {
        product?.id && requestProductExport({ variables: { productID: product?.id, timezone: getBrowserTimezone() } })
      },
      template: MenuItemComponent
    }
  ]

  return (
    <>
      <Menu model={downloadActionList} popup ref={menu} id="popup_menu" className="mt-1 w-auto" />

      { !isDownloadEnabled() && <Tooltip className="w-15rem" target=".disabled-table-download" position="bottom" disabled={isDownloadEnabled()} /> }

      <div className="flex disabled-table-download" data-pr-tooltip={t('messages.description', { context: 'addItemsOrImpact', ns: 'product' })}>
        <Button
          className="p-button p-component p-button-outlined p-button-plain text-sm h-2rem px-2"
          icon={<FontAwesomeIcon icon={faArrowDownToBracket} className="mr-2" />}
          label={t('actions.download', { ns: 'common' })}
          onClick={(event: any) => menu?.current?.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
          data-testid="menu-download-table"
          disabled={!isDownloadEnabled()}
        >
          <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
        </Button>
      </div>

      <Toast data-testid="request-export-status" ref={toast} position="top-right" />
    </>
  )
}

