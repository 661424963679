import { useContext, useEffect } from 'react'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'

import { Navigation } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { InventoryViewType } from '../../product/enum/inventory-view-type'

import { AnalysisMode } from '../enum/analysis'
import { setIsDetailPanelReadonlyV1Action } from '../../../redux/actions/flags.actions'
import { store } from '../../../configureStore'
import { TAnalysisContext } from '../interface/analysis.context'
import AnalysisContext from '../provider/context/analysis.context'
import { TLifecycleDetailContext } from '../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../lifecycle/provider/context/lifecycle-detail.context'

const trackLifecycleNavigation = (activeTab?: InventoryViewType) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const activeIndex = Object.values(InventoryViewType).findIndex(viewType => viewType === activeTab) || 0
  const TabEventTypes: TrackEventType[] = [
    TrackEventType.VIEWED_MODEL,
    TrackEventType.VIEWED_LIFECYCLE_TABLE,
    TrackEventType.VIEWED_LIFECYCLE_DONUT_CHART
  ]
  trackEventInSegment(TabEventTypes[activeIndex])
}

export const useLifecycleAnalysisNavigate = (newAnalysisMode?: AnalysisMode) => {
  const { lifecycleId: lifecycleIdFromURL } = useParams();
  const { space: { id: spaceId = null, slug: workspaceSlug = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {};
  const { account: { id: accountId = null } = {} } = useReactiveVar(selectedAccountVar) || {};

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const {
    secondaryEntityId,
    analysisMode,
    updateAnalysis
  } = useContext<TAnalysisContext>(AnalysisContext);
  const { addToBackToList = () => {}, removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext);
  const { updateLifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext);

  const activeTab = searchParams.get('activeTab');
  const secondaryEntityIdParam = searchParams.get('id');
  const selectedAnalysisMode = newAnalysisMode || analysisMode
  const comparisonMode = selectedAnalysisMode === AnalysisMode.Comparison;

  useEffect(() => {
    if (secondaryEntityIdParam) {
      updateAnalysis({ secondaryEntityId: secondaryEntityIdParam })
    }
  }, [ secondaryEntityIdParam ])

  useEffect(() => {
    const selectedInventoryViewType = activeTab ? activeTab as InventoryViewType : InventoryViewType.Model;
    trackLifecycleNavigation(selectedInventoryViewType);
    updateLifecycleDetail({ selectedInventoryViewType });

    if (!comparisonMode && activeTab !== InventoryViewType.Model) {
      store.dispatch(setIsDetailPanelReadonlyV1Action(true));
    }
  }, [ activeTab ])

  const navigateBackToSource = () => {
    navigate(-1);
    removeFromBackToList();
  }

  const lifecycleAnalysisNavigate = ({
    selectedLifecycleId,
    selectedSecondaryEntityId,
    activeTab = InventoryViewType.Model,
    replace = true,
    backLabel,
  }: {
    selectedLifecycleId?: string;
    selectedSecondaryEntityId?: string;
    activeTab?: InventoryViewType;
    replace?: boolean;
    backLabel?: string | null;
  } = {}) => {
    const lifecycleId = selectedLifecycleId || lifecycleIdFromURL;
    const id = selectedSecondaryEntityId || secondaryEntityId || secondaryEntityIdParam;

    if (!accountId || !workspaceSlug || !lifecycleId || !id) return;

    const isOnLifecycleComparison = isValid(matchPath(Navigation.LifeCycleComparison, pathname));
    const isOnLifecycleScenario = isValid(matchPath(Navigation.LifeCycleScenarioV1, pathname));
    const isSamePrimaryLifecycle = lifecycleId === lifecycleIdFromURL;
    const isSameSecondaryLifecycle = id === secondaryEntityIdParam;

    if (isSamePrimaryLifecycle && isSameSecondaryLifecycle && (isOnLifecycleComparison || isOnLifecycleScenario)) {
      setSearchParams({ activeTab, id }, { replace });
      return;
    }

    if (backLabel) addToBackToList(backLabel);

    const commonPathParams = { accountId, workspaceSlug, lifecycleId };
    const search = `?activeTab=${activeTab}&id=${id}`

    navigate({
      pathname: comparisonMode
        ? generatePath(Navigation.LifeCycleComparison, commonPathParams)
        : generatePath(Navigation.LifeCycleScenarioV1, commonPathParams),
      search
    });
  };

  return {
    spaceId,
    accountId,
    workspaceSlug,
    activeTab,
    lifecycleAnalysisNavigate,
    navigateBackToSource,
  };
}
