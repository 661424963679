import React from 'react'
import styled from 'styled-components'
import { classNames } from 'primereact/utils'
import { ListType } from '../enum'

interface OverviewCardWrapperProps {
  readonly listType: ListType;
}

const getTypeColor = (listType: ListType) => {
  switch (listType) {
  case ListType.Lifecycle:
  case ListType.Product:
  case ListType.Object:
    return { color: 'var(--primary-500)', background: 'var(--primary-50)' }
  case ListType.Folder:
    return { color: 'var(--surface-a)', background: 'var(--primarydark-400)' }
  }
}

const OverviewCardWrapper = styled.div<OverviewCardWrapperProps>`
  &.overview-card {
    border-radius: 0.375rem;
    overflow: hidden;
  }

  .overview-card-icon {
    background: ${(props: OverviewCardWrapperProps) => getTypeColor(props.listType)?.background};
    color: ${(props: OverviewCardWrapperProps) => getTypeColor(props.listType)?.color};
  }

  &.overview-card-default {
    background: var(--surface-a);

    :hover {
      cursor: pointer;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
    }
  }

  &.overview-card-create {
    background-image: url("/assets/border_strock.svg");
    color: var(--gray-500);
    text-align: center;

    :hover {
      cursor: pointer;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.05);
    }
  }

  .overview-card-header {
    height: 2.5rem;
  }
  .overview-card-tag {
    width: calc(100% - 2rem);
    height: 2.5rem;
    overflow: hidden;
  }
  .overview-card-footer {
    height: 1.25rem;
    overflow: hidden;
  }

  .overview-card-body {
    height: calc(100% - 3.25rem);
  }

  .overview-card-label {
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .overview-card-textLabel {
    height: 100%;
  }

  .p-tag-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export enum OverviewCardTemplateType {
  Default = 'Default',
  Create = 'Create'
}

type TOverviewCardTemplateLayoutProps = {
  type?: OverviewCardTemplateType,
  selected?: boolean,
  clickHandler?: () => void,
  children?: React.ReactNode,
  listType?: ListType
}

export const OverviewCardTemplateLayout = ({
  type = OverviewCardTemplateType.Default,
  listType = ListType.Product,
  selected = false,
  clickHandler,
  children
}: TOverviewCardTemplateLayoutProps) => {
  const isDefault = type === OverviewCardTemplateType.Default
  const isCreate = type === OverviewCardTemplateType.Create

  return (
    <OverviewCardWrapper
      data-testid="overview-card-template-wrapper"
      data-cy="product-item"
      onClick={clickHandler}
      listType={listType}
      className={classNames('flex flex-column w-full h-full p-3 overview-card relative', {
        'overview-card-default': isDefault,
        'overview-card-create': isCreate,
        'border-1 border-solid border-green-500': selected
      })}
    >
      { children }
    </OverviewCardWrapper>
  )
}
