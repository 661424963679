import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'

type RecentProductHeaderComponentProps = {
  navigateToProducts: () => void,
  openCreateProductDialog: () => void,
  openImportProductDialog: () => void
}
export const RecentProductHeaderComponent = ({
  navigateToProducts,
  openCreateProductDialog,
  openImportProductDialog
}: RecentProductHeaderComponentProps) => {
  const { t } = useTranslation([ 'workspace', 'common' ])

  return (
    <>
      <div className="flex-grow-1 white-space-nowrap overflow-hidden text-overflow-ellipsis">
        <h2
          className="text-2xl text-gray-700 font-semibold line-height-2 p-0"
          data-testid="recent-products-header"
        >
          { t('labels.recent', { context: 'products' }) }
        </h2>
      </div>
      <div className="flex-none">
        <Button onClick={navigateToProducts} data-testid="recent-products-viewAll" label={t('labels.view', { context: 'all', ns: 'common' })} className="p-button-plain p-button-text text-sm h-2rem mx-1" />
        <Button onClick={openImportProductDialog} data-testid="recent-products-import" label={t('labels.import', { ns: 'common' })} className="p-button-outlined p-button-plain text-sm h-2rem mx-1" />
        <Button onClick={openCreateProductDialog} data-cy="create-new-product" data-testid="recent-products-create" icon="pi pi-plus" className="p-button-outlined p-button-plain w-2rem h-2rem ml-1" />
      </div>
    </>
  )
}
