import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { classNames } from 'primereact/utils'

// import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown, faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OverlayPanel } from 'primereact/overlaypanel'
import { useReactiveVar } from '@apollo/client'

import { selectedWorkspaceVar } from '../../../../graphql/cache'
import { DatabaseFilterListComponent } from './database-filter-list.component'
import { TImpactDatasetContext } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { getSelectedDatabasesStorageItem } from '../../../shared/util/impact'
import { SortTogglerComponent } from './sort-toggler.component'
import { DatasetSortField, SortingOrder } from '../../../../__generated__/graphql'
import { useClickOutside } from '../../../shared/hook/use-outside-click'

type TDatabaseFilterComponent = {
  label?: string
  tableHeaderId?: string
}

export const DatabaseFilterComponent = ({
  label,
  tableHeaderId = ''
}: TDatabaseFilterComponent) => {
  const { space: selectedSpace } = useReactiveVar(selectedWorkspaceVar) || {}
  const overlayPanel = useRef<OverlayPanel>(null)
  const panelContainerRef = useRef<HTMLDivElement>(null)
  const {
    selectedSortBy,
    selectedSortOrder,
    selectedDatabases = [],
    hasActiveDatabaseFilter = false,
    updateImpactDataset,
    updateFilterValue
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const [ addOutsideClickListener, setAddOutsideClickListener ] = useState<boolean>(false)
  useClickOutside(panelContainerRef, () => overlayPanel?.current?.hide(), addOutsideClickListener)
  const { databases: defaultSelectedDatabases = [] } = selectedSpace || {}

  useEffect(() => {
    setDefaultFilterValues()
  }, [ defaultSelectedDatabases ])

  const setDefaultFilterValues = () => {
    if (hasActiveDatabaseFilter) return

    const storedDatabaseItems = getSelectedDatabasesStorageItem() || []
    const selectedDatabaseNames = storedDatabaseItems?.length > 0
      ? storedDatabaseItems
      : defaultSelectedDatabases.map(database => database.name)
    updateImpactDataset({ selectedDatabases: selectedDatabaseNames })
  }

  const isSortByDatabase = selectedSortBy === DatasetSortField.Database

  const handleSortingChange = (sortOrder: any) => {
    if (!sortOrder) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
    } else {
      updateFilterValue({ selectedSortBy: DatasetSortField.Database, selectedSortOrder: sortOrder })
    }

    overlayPanel?.current?.hide()
  }

  return (
    <>
      <OverlayPanel className="top-auto left-auto mt-5" appendTo={document.getElementById(tableHeaderId)} ref={overlayPanel} onShow={() => setAddOutsideClickListener(true)} onHide={() => setAddOutsideClickListener(false)}>
        <div ref={panelContainerRef} className="flex flex-column w-full">
          <div className="flex w-full pb-3 line-height-1 text-sm font-medium border-bottom-1 border-gray-100">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
            <div className="flex flex-grow-1 justify-content-end">
              <SortTogglerComponent
                onViewChange={handleSortingChange}
                selectedSortOrder={selectedSortBy === DatasetSortField.Database ? selectedSortOrder : undefined}
              />
            </div>
          </div>
          <div className="flex align-items-center pt-3">
            <DatabaseFilterListComponent />
          </div>
        </div>
      </OverlayPanel>

      <span
        data-testid="table-header-database-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {
          'text-primary-500': selectedDatabases.length > 0 || isSortByDatabase
        })}
      >
        { label }
        { !isSortByDatabase
          ? <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          : (
            <>
              { selectedSortOrder === SortingOrder.Asc && <FontAwesomeIcon data-testid="database-filter-asc" className="ml-1 text-sm" icon={faArrowDown} /> }
              { selectedSortOrder === SortingOrder.Desc && <FontAwesomeIcon data-testid="database-filter-desc" className="ml-1 text-sm" icon={faArrowUp} /> }
            </>
          )}
      </span>
    </>
  )
}
