import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SimpleCardTemplateComponent } from '../../shared/component/general/simple-card-template.component'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { videoTutorials, VideoItem } from '../constant/video-tutorials'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'

const ActiveVideoInfo = styled.div`
  &:hover {
    background: var(--blue-50);
  }
`

const VideoItemComponent = styled.div`
  &:hover {
    background: var(--blue-50);
  }`

export const VideoTutorialsComponent = () => {
  const { t } = useTranslation([ 'workspace', 'common' ])
  const { pipVideo, updateDashboard } = useContext(DashboardContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  const header = (
    <div className="text-base font-semibold pl-1">
      { t('labels.helpCenter_videoTutorials') }
    </div>
  )

  const onVideoClick = (video: VideoItem) => {
    trackEventInSegment(TrackEventType.VIEWED_VIDEO_TUTORIAL)
    updateDashboard({
      pipVideo: video,
      showPipPlayer: true,
    })
  }

  return (
    <SimpleCardTemplateComponent
      header={header}
      boxShadow="0px 6px 30px 6px rgba(0, 0, 0, 0.05)"
      className="bg-white border-round-lg text-base font-medium text-gray-700 p-4 pr-2"
    >
      <div
        data-testid="active-video"
        className="py-3 mr-3 ml-1 border-top-1 border-bottom-1 border-gray-200 cursor-pointer"
        onClick={() => {
          trackEventInSegment(TrackEventType.VIEWED_VIDEO_TUTORIAL)
          updateDashboard({ showPipPlayer: true })
        }}
      >
        <div className="pb-3">
          <img src={pipVideo?.thumbnail} className="w-full border-round-lg" alt={pipVideo?.title} />
        </div>
        <ActiveVideoInfo className="flex justify-content-between align-items-center p-1 border-round-lg">
          <span className="text-sm font-normal line-height-1 text-gray-700">{pipVideo?.title}</span>
          <span className="text-xs font-normal text-gray-300 pl-3">{pipVideo?.duration}</span>
        </ActiveVideoInfo>
      </div>
      <div
        data-testid="video-tutorials-list"
        className="flex flex-column w-full h-9rem pt-2 overflow-auto pr-2"
      >
        {
          videoTutorials.map((item, index) => (
            <VideoItemComponent
              key={`video-${index}`}
              data-testid="video-tutorial"
              onClick={() => onVideoClick(item)}
              className="flex w-full cursor-pointer justify-content-between align-items-center mb-1 p-1 border-round-lg"
            >
              <div className="flex flex-grow-1 align-items-center">
                <div className="w-3rem mr-2 h-2rem" style={{ minWidth: '3rem' }}>
                  <img src={item.thumbnail} className="w-full" alt={item.title} />
                </div>
                <div className="text-sm font-normal line-height-1 text-gray-700">{item.title}</div>
              </div>
              <div className="flex pl-3 justify-content-end">
                <div className="text-xs font-normal text-gray-300">{item.duration}</div>
              </div>
            </VideoItemComponent>
          ))
        }
      </div>
    </SimpleCardTemplateComponent>
  )
}
