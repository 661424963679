import React, { useState, useContext } from 'react'
import { useReactiveVar } from '@apollo/client'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { getSegmentTrack } from '../../../shared/util/segment'
import { OverviewCardTemplateLayout } from '../../../shared/layout/overview-card-template.component'
import { OverviewViewComponent } from '../../../shared/component/overview/overview-view.component'
import { ListType } from '../../../shared/enum'
import { TrackEventType } from '../../../shared/enum/track-events'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../../graphql/cache'
import { MenuLifecycleContainer } from '../../container/menu/menu-lifecycle.container'
import { ILifecycle } from '../../../model'
import { MenuAction } from '../../enum/menu-action'
import LifecycleContext from '../../provider/context/lifecycle.context'
import { TLifecycleContext } from '../../interface/lifecycle-context.type'
import { normalize } from '../../../../utils/tools'
import { IDashboardContext } from '../../../shared/interface/workspace-context-type'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { TFeatureContext } from '../../../dashboard/interface/feature-context.type'
import FeatureContext from '../../../dashboard/context/feature.context'
import { Feature } from '../../../shared/enum/feature'

type CardItemTemplateComponentProps = {
  lifecycle?: ILifecycle
}

export const CardItemTemplateComponent = ({ lifecycle = {} }: CardItemTemplateComponentProps) => {
  const { t } = useTranslation([ 'common' ])
  const {
    id = '',
    name = '',
    description = '',
    updatedAt = ''
  } = lifecycle
  const navigate = useNavigate()
  const [ trackEventInSegment ] = getSegmentTrack()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}
  const [ isMouseOver, setIsMouseOver ] = useState<boolean>(false)
  const { selectedLifecycle, selectedAction } = useContext<TLifecycleContext>(LifecycleContext)
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { isFeatureEnabled } = useContext<TFeatureContext>(FeatureContext)
  const enableLegacyLifecycleInventory = isFeatureEnabled(Feature.LegacyLifecycleInventory) || false

  const navigateToLifecycleDetail = (id?: string) => {
    trackEventInSegment(TrackEventType.VIEWED_LIFECYCLE)
    addToBackToList(t('labels.lifecycle', { count: 2, ns: 'common' }))
    if (!enableLegacyLifecycleInventory) {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/detail`)
    } else {
      navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/inventory`)
    }
  }

  const isSelected = (id === selectedLifecycle?.id && selectedAction === MenuAction.OpenLifecycleDetail)

  return (
    <div
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      data-cy={`item-named-${normalize(name)}`} // TODO: find a good replacement of normalize()
      className="flex w-full h-full p-0 m-0"
    >
      <OverviewCardTemplateLayout
        key={id}
        selected={isSelected}
        listType={ListType.Lifecycle}
        clickHandler={() => navigateToLifecycleDetail(id)}
      >
        <OverviewViewComponent
          id={id}
          icon={<FontAwesomeIcon icon={faRecycle} className="text-xl" />}
          textLabel={name}
          description={description}
          updatedAt={updatedAt}
        />
        <div className="absolute bottom-0 right-0 pb-3 pr-3">
          <MenuLifecycleContainer {...{ lifecycle, isMouseOver, isSelected }} />
        </div>
      </OverviewCardTemplateLayout>
    </div>
  )
}
