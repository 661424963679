import React, { useContext, useEffect } from 'react'

import { NavigationLayout } from '../layout/navigation.layout'
import { ContentLayout } from '../layout/content.layout'

import { IUseUserResult, useUserQuery } from '../hooks/user-query'
import LoadingPage from '../../../component/shared/loadingPage'
import { FeatureProvider } from '../provider/feature.provider'
import BrowserCompatibility from '../../../component/browserCompatibility'
import DashboardContext from '../context/dashboard.context'

export const DashboardContainer = () => {
  const { loading }: IUseUserResult = useUserQuery()
  const { onSidebarHide = () => {}, openedSidebar } = useContext(DashboardContext)

  const handleCloseSidebar = (event: any) => {
    onSidebarHide(event)
  }

  useEffect(() => {
    const dashboardContainerDom = document.querySelector('#dashboard-container')
    if (openedSidebar) {
      dashboardContainerDom?.addEventListener('click', handleCloseSidebar)
    } else {
      dashboardContainerDom?.removeEventListener('click', handleCloseSidebar)
    }

    return () => dashboardContainerDom?.removeEventListener('click', handleCloseSidebar)
  }, [ openedSidebar ])

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && (
        <FeatureProvider>
          <BrowserCompatibility>
            <div data-testid="dashboard-container" id="dashboard-container" className="flex col-12 h-full p-0 overflow-hidden">
              <NavigationLayout />
              <ContentLayout />
            </div>
          </BrowserCompatibility>
        </FeatureProvider>
      )}
    </>
  )
}
