
import { matchPath } from 'react-router-dom'
import { Navigation, ProductType } from '../../shared/enum'
import { ImpactDatasetViewType } from '../enum/impact-dataset-view-type'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { IProduct } from '../../model'

export const getBackLinkLabel = (t: Function, pathname: string) => {
  if (matchPath(Navigation.ProductOverview, pathname)) {
    return t('labels.product', { count: 2 })
  }
  if (matchPath(Navigation.ObjectOverview, pathname)) {
    return t('labels.object')
  }
  if (matchPath(Navigation.ProductScenario, pathname) || matchPath(Navigation.LifeCycleScenario, pathname)) {
    return t('labels.scenario')
  }
  return t('labels.tab_model')
}

export const getInitialTab = (selectedProduct: IProduct) => {
  let initTab = ImpactDatasetViewType.DatabaseSearch
  const hasCustomImpact = selectedProduct.customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)

  if (!selectedProduct.referenceProduct && hasCustomImpact) {
    initTab = ImpactDatasetViewType.CustomImpact
  } else if (selectedProduct.referenceProduct?.type === ProductType.ElementaryFow) {
    initTab = ImpactDatasetViewType.ElementaryFlow
  }
  return initTab
}

export const trackNavigation = (activeTab: ImpactDatasetViewType, isOnDatasetPage: boolean = false) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  if (!isOnDatasetPage) trackEventInSegment(TrackEventType.OPENED_DATABASE_SEARCH_WINDOW)

  const TabEventTypes: TrackEventType[] = [
    TrackEventType.VIEWED_REFERENCE_SEARCH,
    TrackEventType.VIEWED_ELEMENTARY_FLOWS_SEARCH,
    TrackEventType.VIEWED_CUSTOM_IMPACT_FORM
  ]

  const activeIndex = Object.values(ImpactDatasetViewType).findIndex(datasetViewType => datasetViewType === activeTab)
  trackEventInSegment(TabEventTypes[activeIndex])
}
