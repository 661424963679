import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsis, faSidebarFlip, faBringForward,
  faFileCirclePlus, faFolder, faTrash
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

import { MenuAction } from '../../enum/menu-action'
import { clearUiWhenDetailPanelCloseAction } from '../../../../redux/actions/clear.actions'
import FlagsSelector from '../../../../redux/selectors/flags.selector'
import LifecycleContext from '../../provider/context/lifecycle.context'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import { setSelectedLifecycleAction } from '../../../../redux/actions/lifecycle.actions'
import { ILifecycle } from '../../../model'
import { setIsDetailsPanelOpenAction } from '../../../../redux/actions/flags.actions'
import DashboardContext from '../../../dashboard/context/dashboard.context'
import { ViewType } from '../../../shared/enum'
import { store } from '../../../../configureStore'

const CardMenuContainerWrapper = styled.div`
  .p-button-outlined.p-button-plain {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--gray-300);
    box-shadow: none !important;
  }
`

export type CardMenuContainerProps = {
  lifecycle: ILifecycle,
  isMouseOver: boolean,
  isSelected?: boolean,
}
export const MenuLifecycleContainer = ({ lifecycle, isMouseOver, isSelected = false }: CardMenuContainerProps) => {
  const { t } = useTranslation([ 'common' ])
  const { id, name, scenarios = [] } = lifecycle
  const toast = useRef<Toast>(null)
  const {
    updateLifecycle,
    selectedLifecycle,
    selectedAction,
    selectedViewType
  } = useContext(LifecycleContext)
  const {
    updateDashboard
  } = useContext(DashboardContext)
  const menu = useRef<Menu>(null)

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const isDetailsPanelOpen = FlagsSelector.isDetailsPanelOpen(state)
  useEffect(() => {
    if (!isDetailsPanelOpen && selectedAction === MenuAction.OpenLifecycleDetail) {
      updateLifecycle({ selectedLifecycle: null })
    }
  }, [ isDetailsPanelOpen ])

  const executeCommand = (event: any) => {
    event.originalEvent.stopPropagation()
    const { action } = event.item
    const selectedLifecycle = { id, name, scenarios }
    switch (action) {
    case MenuAction.CopyLifecycle:
      updateLifecycle({ showCopyLifecycleDialog: true, selectedLifecycle, selectedAction: action })
      break
    case MenuAction.CreateScenario:
      updateLifecycle({ showCreateScenarioDialog: true, selectedLifecycle })
      break
    case MenuAction.CompareLifecycle:
      updateLifecycle({ selectedLifecycle })
      updateDashboard({ showCreateComparisonDialog: true })
      break
    case MenuAction.DeleteLifecycle:
      updateLifecycle({ showDeleteLifecycleDialog: true, selectedLifecycle })
      break
    default: break
    }
  }

  const items = [
    {
      action: MenuAction.CopyLifecycle,
      label: 'Copy life cycle',
      icon: <FontAwesomeIcon icon={faFolder} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.CompareLifecycle,
      label: 'Compare life cycle',
      icon: <FontAwesomeIcon icon={faBringForward} rotation={90} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.CreateScenario,
      label: t('actions.create', { context: 'scenario' }),
      icon: <FontAwesomeIcon icon={faFileCirclePlus} className="text-sm" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.DeleteLifecycle,
      label: 'Delete life cycle',
      icon: <FontAwesomeIcon icon={faTrash} className="text-sm" />,
      textColor: '--red-500',
      command: executeCommand,
      template: MenuItemComponent
    }
  ]

  const onMenuClick = (event: any) => {
    event.stopPropagation()
    menu.current?.toggle(event)
  }

  const onProductInfo = (event: any) => {
    event.stopPropagation()
    if (selectedLifecycle?.id === id) {
      updateLifecycle({ selectedLifecycle: null })
      store.dispatch(setIsDetailsPanelOpenAction(false))
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      return
    }
    updateLifecycle({ selectedLifecycle: { id, name }, selectedAction: MenuAction.OpenLifecycleDetail })
    store.dispatch(setIsDetailsPanelOpenAction(true))
    store.dispatch(setSelectedLifecycleAction(lifecycle))
  }

  const isTableView = selectedViewType === ViewType.Tabular

  return (
    <>
      <CardMenuContainerWrapper className="flex flex-none gap-2 justify-content-end">
        <Menu model={items} popup ref={menu} id="popup_menu" className="mt-1" />

        <Button
          visible={isMouseOver}
          icon={<FontAwesomeIcon icon={faEllipsis} className="text-lg" />}
          data-testid="card-menu-more"
          data-cy="card-menu-button"
          onClick={onMenuClick}
          className={classNames('p-button-outlined p-button-plain p-button-sm p-0 border-none', {
            'flex-order-1': isTableView
          })}
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Button
          onClick={onProductInfo}
          icon={<FontAwesomeIcon icon={faSidebarFlip} className="text-lg" />}
          data-testid="card-menu-info"
          className={classNames('p-button-outlined p-button-plain p-button-sm p-0 border-none', {
            'flex-order-0': isTableView,
            'text-primary-500': isSelected
          })}
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Toast data-testid="card-menu-status" ref={toast} position="top-right" />
      </CardMenuContainerWrapper>
    </>
  )
}
