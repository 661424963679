import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import ProductDetailContext from '../provider/context/product-detail.context'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import DetailsPanel from '../../../component/model/details-panel/detailsPanel.container'
import ProductDetails from '../../../component/model/details-panel/product-details/productDetails.container'
import { store } from '../../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../../redux/actions/clear.actions'
import { MenuAction } from '../enum/menu-action'
import { CreateComparisonContainer } from '../../shared/container/create-comparison.container'
import { ILifecycle, IProduct } from '../../model'
import { TrackEventType } from '../../shared/enum/track-events'
import { EntityType } from '../../shared/enum/entity-type'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { CreateLifeCycleContainer } from '../../shared/container/create-lifecycle.container'
import ProductMutationSelector from '../../../redux/selectors/productMutation.selector'
import { SelectedProductSelector } from '../../../redux/selectors/product.selector'
import { updateInventoryItemsSaga } from '../../../redux/sagas/inventoryTree.saga'
import ImpactSelectorDialog from '../../../component/helpers/dialogs/impactSelectorDialog/impactSelectorDialog.container'
import { getSegmentTrack } from '../../shared/util/segment'
import { CreateScenarioContainer } from '../container/dialog/create-scenario.container'
import { CreateProductLifeCycleContainer } from '../../shared/container/create-product-lifecycle.container'
import { ExportProductCsvContainer } from '../container/dialog/export-product-csv.container'
import { ProductDetailLayout } from '../layout/product-detail.layout'

import { UpdateProductPropertyContainer } from '../../shared/container/update-product-property.container'
import { useProductAnalysisNavigate } from '../../analysis/hook/use-product-analysis-navigate'
import { useLifecycleNavigate } from '../../lifecycle/hook/use-lifecycle-navigate'

export const ProductDetailComponent = () => {
  const { productAnalysisNavigate } = useProductAnalysisNavigate()
  const { lifecycleNavigate } = useLifecycleNavigate()
  const { productId = '' } = useParams()

  const {
    productInventory,
    showExportProductCsv = false,
    selectedAction,
    updateProductDetail,
    refetchInventory = () => {},
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const {
    showCreateComparisonDialog = false,
    showCreateLifeCycleDialog = false,
    showCreateLifeCycleFromProduct = false,
    showUpdatePropertyDialog = false,
    addToBackToList = () => {},
    updateDashboard = () => {}
  } = useContext<IDashboardContext>(DashboardContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  // TODO : Added for compatibility with v0.9
  const state = store.getState()
  const productMutator = ProductMutationSelector.productMutator(state)
  const selectedProductId = SelectedProductSelector.productId(state)

  const isAddedTreeItemAction = () => selectedAction === MenuAction.AddTreeItem

  const afterUpdateProductDetail = ({ isClosedAfterAddItem = true } = {}) => {
    if (isAddedTreeItemAction() && isClosedAfterAddItem) {
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      updateProductDetail({ selectedAction: null, selectedProduct: null, selectedInventoryAmountV09: null })
    }

    reloadInventoryItems()
    isAddedTreeItemAction() && trackEventInSegment(TrackEventType.CREATED_SUBITEM)
  }

  const afterCreateComparison = (comparisonEntity: IProduct | ILifecycle) => {
    trackEventInSegment(TrackEventType.OPENED_COMPARISON)
    productInventory?.product?.name && addToBackToList(productInventory?.product?.name)
    if (comparisonEntity.__typename !== EntityType.LifeCycle) {
      productAnalysisNavigate({
        selectedProductId: productId,
        selectedSecondaryEntityId: comparisonEntity.id,
        selectedSecondaryEntityType: comparisonEntity.__typename,
        backLabel: productInventory?.product?.name
      })
    }
  }

  const openDetailAfterCreate = (selectedLifecycleId: string, trackEventType?: TrackEventType) => {
    trackEventType && trackEventInSegment(trackEventType)
    updateDashboard({ showCreateLifeCycleFromProduct: false })
    lifecycleNavigate({
      selectedLifecycleId,
      backLabel: productInventory?.product?.name
    })
  }

  const afterImpactChange = () => {
    reloadInventoryItems()
    store.dispatch(updateInventoryItemsSaga())
  }

  const reloadInventoryItems = () => {
    refetchInventory()
    refetchInventoryItems()
    updateProductDetail({ hasInventoryChanged: true })
  }

  return (
    <>
      <ProductDetailLayout />

      { showCreateComparisonDialog && <CreateComparisonContainer afterCreateComparison={afterCreateComparison} /> }

      <CreateScenarioContainer />

      { showCreateLifeCycleDialog && <CreateLifeCycleContainer afterCreateLifeCycle={openDetailAfterCreate} /> }

      { showCreateLifeCycleFromProduct && <CreateProductLifeCycleContainer product={productInventory?.product} /> }

      { showExportProductCsv && <ExportProductCsvContainer />}

      {showUpdatePropertyDialog && <UpdateProductPropertyContainer onUpdateCallback={reloadInventoryItems} />}

      {/* // TODO : From v0.9 : To be refactored */}
      <DetailsPanel>
        <ProductDetails isEnabledForV1 updatePageItems={afterUpdateProductDetail} />
      </DetailsPanel>

      {/* // TODO : From v0.9 : To be refactored */}
      { selectedProductId && <ImpactSelectorDialog productMutator={productMutator} updatePageItems={afterImpactChange} /> }
    </>
  )
}
