import { gql } from '../../../__generated__'

export const REFERENCE_DATABASE = gql(`
  query referenceDatabases($methodId: ID!) {
    referenceDatabases(methodId: $methodId) {
        id
        name
        version
    }
  }
`)

export const CATEGORIES = gql(`
  query categories($parentId: String!, $types: [String!]!) {
    categories(parentId: $parentId, types: $types) {
        id
        name
        description
        isLeaf
    }
  }
`)

export const REFERENCE_PROPERTIES = gql(`
  query referenceProperties {
    referenceProperties {
      id
      name
      referenceUnits {
        id
        name
        conversionUnitID
      }
    }
  }
`)

export const SEARCH_DATASETS = gql(`
  query searchDatasets($query: String!, $impactMethodId: String!, $categoryId: String!, $properties: [FilterDatasetProperty!]!, $pagination: SearchPagination!, $searchType: SearchType = EXACT, $sortBy: SortDataset) {
    searchDatasets(query: $query, impactMethodId: $impactMethodId, categoryId: $categoryId, properties: $properties, pagination: $pagination, searchType: $searchType, sortBy: $sortBy) {
      items{
        id
        name
        description
        databaseID
        databaseName
        type
        referenceProduct
        technology
        timeDescription
        timeFrom
        timeUntil
        geography
        databaseCategoryPath
        referenceUnit {
            id
            name
        }
        referenceProperty {
            id
            name
        }
        amount
        location {
          name
        }
        category {
          name
        }
        parentCategory {
          name
        }

      }
      facets {
        name
        values {
          name
          count
        }
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
    }
  }
`)

export const SEARCH_ELEMENTARY_FLOWS = gql(`
  query searchElementaryFlows($query: String!, $impactMethodId: String!, $categoryId: String!, $properties: [FilterElementaryFlowProperty!]!, $pagination: SearchPagination!, $searchType: SearchType = EXACT, $sortBy: SortDataset) {
    searchElementaryFlows(query: $query, impactMethodId: $impactMethodId, categoryId: $categoryId, properties: $properties, pagination: $pagination, searchType: $searchType, sortBy: $sortBy) {
      items{
        id
        name
        description
        databaseID
        databaseName
        type
        databaseCategoryPath
        referenceUnit {
          id
          name
        }
        referenceProperty {
          id
          name
        }
        amount
        location {
          name
        }
        category {
          name
        }
        parentCategory {
          name
        }

      }
      facets {
        name
        values {
          name
          count
        }
      }
      pagination {
        currentPage
        pageSize
        totalPages
        totalResults
      }
    }
  }
`)
