import { useContext, useEffect } from 'react'
import {
  useLocation,
  useParams,
  useNavigate,
  generatePath,
  matchPath,
  useSearchParams
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import { InventoryViewType } from '../enum/inventory-view-type'
import { Navigation } from '../../shared/enum'
import { isValid } from '../../shared/util/tools'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'

const trackProductNavigation = (activeTabKey?: InventoryViewType) => {
  const [ trackEventInSegment ] = getSegmentTrack()
  const activeIndex = Object.values(InventoryViewType).findIndex(viewType => viewType === activeTabKey) || 0
  const TabEventTypes: TrackEventType[] = [
    TrackEventType.VIEWED_MODEL,
    TrackEventType.VIEWED_PRODUCT_TABLE,
    TrackEventType.VIEWED_PRODUCT_BAR_CHART,
    TrackEventType.VIEWED_PRODUCT_DONUT_CHART
  ]
  trackEventInSegment(TabEventTypes[activeIndex])
}

export const useProductNavigate = () => {
  const { productId: productIdFromURL } = useParams()
  const { space: { slug: workspaceSlug = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account: { id: accountId = null } = {} } = useReactiveVar(selectedAccountVar) || {}

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { addToBackToList = () => {}, removeFromBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const { updateProductDetail } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const activeTab = searchParams.get('activeTab')

  useEffect(() => {
    const selectedInventoryViewType = activeTab ? activeTab as InventoryViewType : InventoryViewType.Model
    trackProductNavigation(selectedInventoryViewType)
    updateProductDetail({ selectedInventoryViewType })
  }, [ activeTab ])

  const navigateBackToSource = () => {
    navigate(-1)
    removeFromBackToList()
  }

  const productNavigate = ({
    selectedProductId,
    activeTab = InventoryViewType.Model,
    replace = true,
    backLabel,
  }: {
    selectedProductId?: string;
    activeTab?: InventoryViewType;
    replace?: boolean;
    backLabel?: string;
  } = {}) => {
    const productId = selectedProductId || productIdFromURL
    if (!accountId || !workspaceSlug || !productId) return;

    const isOnProductDetail = isValid(matchPath(Navigation.ProductObjectInventory, pathname));
    const isSameProduct = !selectedProductId || selectedProductId === productIdFromURL;

    if (isSameProduct && isOnProductDetail) {
      setSearchParams({ activeTab }, { replace });
      return;
    }

    if (backLabel) addToBackToList(backLabel);

    navigate({
      pathname: generatePath(Navigation.ProductObjectInventory, {
        accountId,
        workspaceSlug,
        productId,
      }),
      search: `?activeTab=${activeTab}`,
    });
  };

  return {
    accountId,
    workspaceSlug,
    activeTab,
    productNavigate,
    navigateBackToSource,
  }
}
