import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Toast } from 'primereact/toast'
import { useTranslation } from 'react-i18next'
import ProductContext from '../../provider/context/product.context'
import { TProductContext } from '../../interface/product-context.type'
import { COPY_PRODUCT } from '../../../graphql/mutation'
import { CreateScenarioComponent } from '../../component/dialog/create-scenario.component'
import { displayGraphqlErrors } from '../../../shared/util/error'
import { PRODUCT_WITH_IMPACT } from '../../../graphql/query'
import { IProduct } from '../../../model'
import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { useProductAnalysisNavigate } from '../../../analysis/hook/use-product-analysis-navigate'
import { AnalysisMode } from '../../../analysis/enum/analysis'

export const CreateScenarioContainer = () => {
  const { t } = useTranslation([ 'common' ])

  const { productAnalysisNavigate } = useProductAnalysisNavigate(AnalysisMode.Scenario)
  const toast = useRef<Toast>(null)
  const [ productWithImpact, setProductWithImpact ] = useState<IProduct | null>(null)
  const {
    showCreateScenarioDialog = false,
    updateProduct,
    selectedEntity
  } = useContext<TProductContext>(ProductContext)

  const selectedEntityID: string = selectedEntity?.id || ''
  const {
    loading: loadingProductWithImpact,
    data: productWithImpactData
  } = useQuery(PRODUCT_WITH_IMPACT, {
    skip: !selectedEntityID || !showCreateScenarioDialog,
    variables: { id: selectedEntityID },
    fetchPolicy: 'no-cache'
  })
  const [ trackEventInSegment ] = getSegmentTrack()

  useEffect(() => {
    if (!loadingProductWithImpact && productWithImpactData) {
      const { product = null } = productWithImpactData?.productWithImpact || {}
      setProductWithImpact(product as IProduct)
    }
  }, [ loadingProductWithImpact, productWithImpactData ])
  const [
    copyProduct,
    {
      error: failedCopyingProduct,
      data: copyProductData,
      loading: copyingProduct
    }
  ] = useMutation(COPY_PRODUCT)
  const copiedProduct = copyProductData?.copyProduct

  useEffect(() => {
    if (!copyingProduct && (copiedProduct || failedCopyingProduct)) {
      createScenarioCallback()
    }
  }, [ copyingProduct, copiedProduct, failedCopyingProduct ])

  const closeCreateScenarioDialog = () => {
    updateProduct({ selectedEntity: null, showCreateScenarioDialog: false })
  }

  const handleCreateScenario = () => {
    const productID = selectedEntity?.id
    productID && copyProduct({ variables: { productID } })
  }

  const createScenarioCallback = () => {
    try {
      if (failedCopyingProduct) {
        throw failedCopyingProduct
      } else if (copiedProduct) {
        trackEventInSegment(TrackEventType.CREATED_PRODUCT_SCENARIO)
        chooseScenario(copiedProduct.id)
        closeCreateScenarioDialog()
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'createScenario' }),
          life: 3000
        })
      }
    } catch (error: any) {
      displayGraphqlErrors(toast, t('messages.errorSummary', { context: 'createScenario' }), error?.graphQLErrors, t)
    }
  }

  const chooseScenario = (scenarioId: string) => {
    trackEventInSegment(TrackEventType.OPENED_PRODUCT_SCENARIO)
    productAnalysisNavigate({
      selectedProductId: selectedEntity?.id,
      selectedSecondaryEntityId: scenarioId,
      backLabel: selectedEntity?.name
    })
  }

  return (
    <>
      { !loadingProductWithImpact && productWithImpact && (
        <CreateScenarioComponent
          copyingProduct={copyingProduct}
          handleChooseScenario={chooseScenario}
          productWithImpact={productWithImpact}
          handleCreateScenario={handleCreateScenario}
          closeCreateScenarioDialog={closeCreateScenarioDialog}
          showCreateScenarioDialog={showCreateScenarioDialog}
        />
      )}

      <Toast data-testid="create-scenario-status" ref={toast} position="top-right" />
    </>
  )
}
