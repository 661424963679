import React, { useState } from 'react'

import styled from 'styled-components'
import { classNames } from 'primereact/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'

import { DatasetSortField, SortingOrder } from '../../../../__generated__/graphql'
import { EDatabaseSearchColumn, EElementaryFlowColumn } from '../../enum/impact-dataset-column'
import { DatabaseFilterComponent } from '../filter/database-filter.component'
import { isValid } from '../../../shared/util/tools'
import { PropertyFilterComponent } from '../filter/property-filter.component'
import { GeographyFilterComponent } from '../filter/geography-filter.component'

const TableHeaderComponentWrap = styled.div`

  .table-header-label {
    padding: 0.188rem 0;
  }

  &.table-sortable-column:hover {
    cursor: pointer;
    span {
      color: var(--blue-500) !important;
    }
  }

  &.active-sort {
    span {
      color: var(--blue-500) !important;
    }
  }

`

type TableHeaderComponentProp<T> = {
  label: string,
  sortBy?: T,
  sortDisabled?: boolean,
  selectedSortBy?: T,
  selectedSortOrder?: SortingOrder,
  column?: EDatabaseSearchColumn | EElementaryFlowColumn,
  onSortClick?: (param1: T, param2: SortingOrder) => void
}

export const TableHeaderComponent = <T extends string>({
  label,
  sortBy,
  column,
  sortDisabled = false,
  selectedSortBy,
  selectedSortOrder,
  onSortClick
}: TableHeaderComponentProp<T>) => {
  const [ isMouseOver, setIsMouseOver ] = useState(false)

  const isAsc = selectedSortOrder === SortingOrder.Asc
  const isDesc = selectedSortOrder === SortingOrder.Desc || !selectedSortOrder
  const sortOrderTo: SortingOrder = isAsc ? SortingOrder.Desc : SortingOrder.Asc

  const isColumnDatabase = column === EDatabaseSearchColumn.Database
  const isDatasetName = column === EDatabaseSearchColumn.DatasetName
  const isColumnGeography = column === EDatabaseSearchColumn.Geography
  const isColumnProperty = column === EDatabaseSearchColumn.Property

  const showArrow = (isMouseOver || selectedSortBy === sortBy) && sortBy && !sortDisabled
  const onTableHeaderClick = () => onSortClick && sortBy && !sortDisabled && onSortClick(sortBy, sortOrderTo)
  const isDatasetFilterActive = isDatasetName && selectedSortBy === DatasetSortField.Name && selectedSortOrder
  const tableHeaderId = `table-header-${label}`

  return (
    <TableHeaderComponentWrap
      id={tableHeaderId}
      data-testid="table-header-wrap"
      className={classNames('flex font-normal', {
        'table-sortable-column': isValid(showArrow) && showArrow,
        'active-sort': isDatasetFilterActive
      })}
      onClick={onTableHeaderClick}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >

      { isColumnDatabase && <DatabaseFilterComponent label={label} tableHeaderId={tableHeaderId} /> }

      { isColumnGeography && <GeographyFilterComponent label={label} tableHeaderId={tableHeaderId} /> }

      { isColumnProperty && <PropertyFilterComponent label={label} tableHeaderId={tableHeaderId} />}

      { !isColumnDatabase && !isColumnGeography && !isColumnProperty
      && (
        <span data-testid="table-header-label" className="mr-1 text-gray-300 text-sm table-header-label">
          { label }
        </span>
      )}

      {showArrow && (
        <span className="ml-1 text-gray-600">
          { isAsc && <FontAwesomeIcon data-testid="table-header-asc" className="text-sm" icon={faArrowDown} /> }
          { isDesc && <FontAwesomeIcon data-testid="table-header-desc" className="text-sm" icon={faArrowUp} /> }
        </span>
      )}
    </TableHeaderComponentWrap>
  )
}
