import React, {
  useRef, useState, useEffect, useContext
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Toast } from 'primereact/toast'

import { ProductTemplatesLayout } from '../layout/product-templates.layout'
import { AddProductFromTemplateComponent } from '../component/dialog/add-product-from-template.component'
import { selectedWorkspaceVar } from '../../../graphql/cache'
import { TWorkspaceContext } from '../interface/workspace-context.type'
import WorkspaceContext from '../provider/context/workspace.context'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { PRODUCT_TEMPLATES } from '../constant/product-templates'
import { IMPORT_PRODUCT_TEMPLATE } from '../../graphql/mutation'
import { displayGraphqlErrors } from '../../shared/util/error'
import { ProductTemplate } from '../../../__generated__/graphql'
import { IProductTemplate, IProductTemplateCategory } from '../interface/product-template'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { ErrorCode } from '../../shared/enum/error';
import { useProductNavigate } from '../../product/hook/use-product-navigate'

export const ProductTemplatesContainer = () => {
  const { t } = useTranslation([ 'common', 'workspace' ])
  const [ isListExpanded, setIsListExpanded ] = useState(false)
  const [ activeTemplate, setActiveTemplate ] = useState<IProductTemplate | null>(null)
  const [ trackEventInSegment ] = getSegmentTrack()

  const { space: { id: spaceID = null } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { productNavigate } = useProductNavigate()
  const { refetchRecentProducts = () => {} } = useContext<TWorkspaceContext>(WorkspaceContext)
  const { updateDashboard } = useContext<IDashboardContext>(DashboardContext)
  const [
    importProductTemplate,
    {
      error: failedProductTemplate,
      data: productTemplateData,
      loading: productTemplateCreating
    }
  ] = useMutation(IMPORT_PRODUCT_TEMPLATE)
  const productTemplateCreated = productTemplateData?.importProductTemplate || []

  const toast = useRef<Toast>(null)

  useEffect(() => {
    if (!productTemplateCreating) {
      importProductTemplateCallback()
    }
  }, [ productTemplateCreating ])

  const createProductFromTemplate = async (spaceID: string, templateKey: ProductTemplate) => {
    if (spaceID && templateKey) {
      importProductTemplate({ variables: { spaceID, productTemplate: templateKey } })
      trackEventInSegment(TrackEventType.CLICKED_PRODUCT_TEMPLATE, { template: templateKey })
    }
  }

  const onTemplateAdding = (templateKey:ProductTemplate) => {
    spaceID && templateKey && createProductFromTemplate(spaceID, templateKey)
  }

  const closeDialog = () => {
    setActiveTemplate(null)
  }

  const importProductTemplateCallback = () => {
    try {
      if (failedProductTemplate) {
        throw failedProductTemplate
      } else if (productTemplateCreated.length > 0) {
        toast?.current?.show({
          severity: 'success',
          summary: t('messages.successSummary'),
          detail: t('messages.success', { context: 'addingProductTemplate', ns: 'workspace' }),
          life: 3000
        })
        navigateToProductDetail()
        setIsListExpanded(false)
      }
    } catch (error: any) {
      const errorCode = error?.graphQLErrors?.[0]?.extensions?.code

      let errorSummaryContext = ''
      if (errorCode === ErrorCode.ACCESS_FORBIDDEN) {
        errorSummaryContext = 'importProductForbidden'
      } else if (errorCode === ErrorCode.DATASET_USAGE_LIMIT_REACHED) {
        updateDashboard({ showDatasetLimitDialog: true })
        return
      }

      displayGraphqlErrors(toast, t('messages.errorSummary', { context: errorSummaryContext }), error?.graphQLErrors, t)
      refetchRecentProducts()
    } finally {
      closeDialog()
    }
  }

  const navigateToProductDetail = () => {
    if (productTemplateCreated.length > 0) {
      const selectedProductId = productTemplateCreated[0].id
      productNavigate({ selectedProductId })
    }
  }

  const mappedTemplateList = () => {
    const popularOrderedList = {
      industry: 'mostPopular',
      templates: PRODUCT_TEMPLATES.filter(item => item.isPopular).sort((a, b) => {
        // Priority sorting for 'ProductStructure'
        if (a.key === ProductTemplate.ProductStructureV39) return -1
        // StickyNote should be the second item in the list
        if (a.key === ProductTemplate.StickyNoteV39 && b.key !== ProductTemplate.ProductStructureV39) return -1

        if (b.key === ProductTemplate.ProductStructureV39 || b.key === ProductTemplate.StickyNoteV39) return 1

        // Default sorting by name
        return a.name.localeCompare(b.name)
      })
    }

    const orderedByIndustryList = PRODUCT_TEMPLATES.sort((a:IProductTemplate, b:IProductTemplate) => {
      if (a.industry !== b.industry) {
        return a.industry.localeCompare(b.industry)
      }
      return a.name.localeCompare(b.name)
    })

    const groupedByIndustryList = orderedByIndustryList.reduce((categories:IProductTemplateCategory[], template:IProductTemplate) => {
      const groupIndex = categories.findIndex((group:IProductTemplateCategory) => group.industry === template.industry)

      if (groupIndex !== -1) {
        categories[groupIndex].templates.push(template)
      } else {
        categories.push({
          industry: template.industry,
          templates: [ template ]
        })
      }

      return categories
    }, [])

    return [ popularOrderedList, ...groupedByIndustryList ]
  }

  return (
    <>
      <ProductTemplatesLayout
        templatesByCategory={mappedTemplateList()}
        isListExpanded={isListExpanded}
        setIsListExpanded={setIsListExpanded}
        setActiveTemplate={setActiveTemplate}
      />
      <Toast data-testid="create-product-template-status" ref={toast} position="top-right" />

      <AddProductFromTemplateComponent
        activeTemplate={activeTemplate}
        closeDialog={closeDialog}
        onTemplateAdding={onTemplateAdding}
        productTemplateCreating={productTemplateCreating}
      />
    </>
  )
}
