import React, { useContext } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IDashboardContext } from '../../interface/workspace-context-type';
import { TooltipOverflowContent, useRefOverflow } from '../../hook/use-ref-overflow';

type TDetailPanelHeaderComponentProps = {
  icon: React.ReactNode,
  title: string,
  afterCloseDetailPanel?: Function
}
export const DetailPanelHeaderComponent = ({
  icon,
  title,
  afterCloseDetailPanel = () => {}
}: TDetailPanelHeaderComponentProps) => {
  const [ titleRef, hasTitleOverflow, titleDom ] = useRefOverflow();
  const { closeSidebar = () => {} } = useContext<IDashboardContext>(DashboardContext);

  const onClose = () => {
    closeSidebar();
    afterCloseDetailPanel();
  }

  const getTooltipContent = () => (
    <TooltipOverflowContent>
      {' '}
      {title}
      {' '}
    </TooltipOverflowContent>
  );

  return (
    <div className="flex w-full h-full p-4 pt-5 gap-3">
      <Tooltip target={titleDom} onBeforeShow={() => hasTitleOverflow} position="bottom">{getTooltipContent()}</Tooltip>
      <div className="flex align-items-center justify-content-center">{ icon }</div>
      <div ref={titleRef} className="flex-grow-1 align-items-center font-medium text-gray-700 text-base white-space-nowrap overflow-hidden text-overflow-ellipsis">{ title }</div>
      <div className="flex align-items-center">
        <FontAwesomeIcon data-testid="clear-search-fields" icon={faXmark} className="text-xl text-gray-500 cursor-pointer" onClick={onClose} />
      </div>
    </div>
  );
}
